export function isChartTypeAllowed(chartType, categories) {
  const clonedCategories = categories.slice();

  function checkType(type) {
    const found = clonedCategories.findIndex(category => category.type === type);

    if (found > -1) {
      clonedCategories.splice(found, 1);
      return true;
    }
    return false;
  }
  
  return chartType.requiredTypes.every(type => {
    if (Array.isArray(type)) {
      return type.some(checkType);
    }

    return checkType(type);
  });
}