/**
 * Get the linear interpolation between to values
 * 
 * @param {number} min Smaller-end (0) value
 * @param {number} max Bigger-end (1) value
 * @param {number} amount Value between 0 and 1 used to interpolate min and max
 */
export function lerp(min, max, amount) {
  return (1 - amount) * min + amount * max;
}

window.lerp = lerp;
