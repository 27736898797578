import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { tableChartDataBuilder } from '../../util';
import { CHART_PROP_TYPES } from '../const';
import { useTableChart } from '../../hooks';
import { IconSort, IconDropdown } from '../../icons';

export function TableChart({ data, categories }) {
  const { headers, rows, total } = useMemo(() => tableChartDataBuilder(categories, data), [categories, data]);
  const { uid, saving, scriptSrc } = useTableChart(rows, headers, total);
  const [sortColumn, setSortColumn] = useState('');

  function sort(column) {
    if (sortColumn === column) {
      return setSortColumn(`${column}-`);
    }
    if (sortColumn === `${column}-`) {
      return setSortColumn('');
    }
    return setSortColumn(column);
  }

  const sortedRows = rows.slice();
  if (sortColumn && !saving) {
    const sortColumnName = sortColumn.endsWith('-') ? sortColumn.substring(0, sortColumn.length - 1) : sortColumn;
    sortedRows.sort((a, b) => {
      const aCol = a.find(([column]) => column === sortColumnName);
      const bCol = b.find(([column]) => column === sortColumnName);

      const aValue = ((typeof aCol[2] === 'number' || aCol[2] === null) ? aCol[2] : aCol[1]) ?? 0;
      const bValue = (typeof aValue === 'number' ? bCol[2] : bCol[1]) ?? 0;

      if (sortColumn.endsWith('-')) {
        if (bValue > aValue) {
          return 1;
        }
        if (bValue < aValue) {
          return -1;
        }
      } else {
        if (aValue > bValue) {
          return 1;
        }
        if (aValue < bValue) {
          return -1;
        }
      }
      return 0;
    });
  }

  return (
    <>
      <div className="table-chart" id={uid} name="TableChart">
        {!saving && (
          <table className="table is-fullwidth">
            <thead>
              <tr>
                {headers.map((header) => {
                  const isSortColumn = sortColumn.startsWith(header);
                  const isAscendingOrder = sortColumn.endsWith('-');
                  const Icon = isSortColumn ? IconDropdown : IconSort;

                  return (
                    <th
                      className={`cel-${_.kebabCase(header)}`}
                      key={header}
                      onClick={() => sort(header)}
                    >
                      {header}
                      <span className={classNames('icon', { dropup: isSortColumn && isAscendingOrder })}>
                        <Icon />
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {sortedRows.map(row => (
                <tr key={row.slice(-1)}>
                  {row.filter(Array.isArray).map(dataset => (
                    <td key={dataset[0]} className={`cel-${_.kebabCase(dataset[0])}`}>
                      {dataset[1]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                {headers.map((header, index) => {
                  if (headers[index + 1] && headers[index + 1] === 'Investment') {
                    return <td className="has-text-right has-text-weight-bold" key={header}>Total Investment:</td>;
                  }
                  if (header === 'Investment') {
                    return (
                      <td className="has-text-right has-text-weight-bold" key={header}>
                        {total}
                      </td>
                    );
                  }
                  return <td key={header} />;
                })}
              </tr>
            </tfoot>
          </table>
        )}
      </div>
      <script src={scriptSrc} type="application/javascript" />
    </>
  );
}

TableChart.propTypes = CHART_PROP_TYPES;
