import { currencyFormatConstruct, currencyFormat } from './currencyFormat';
import { scatterLabelFormatter, scatterLabelFormatterConstruct } from './scatterLabelFormatter';

export function createDrawFunction({ uid, options }) {
  if (uid) {
    const { formatter, type } = options.options.yaxis.labels;
    /**
     * If there's a custom formatter for Y-axis labels, add an array of arguments to the Function constructor,
     * so that the formatter function can be recreated when deployed/embedded.
     */
    if (formatter) {
      // eslint-disable-next-line default-case
      switch (type) {
        case currencyFormat:
          // eslint-disable-next-line no-param-reassign
          options.options.yaxis.labels.formatterConstruct = currencyFormatConstruct;
          break;
        case scatterLabelFormatter:
          // eslint-disable-next-line no-param-reassign
          options.options.yaxis.labels.formatterConstruct = scatterLabelFormatterConstruct;
          break;
      }
    }
    /**
     * function _datasailsDraw${uid} = Name of callback to window._apexBuilders, draws chart
     * window._apexBuilders.push(_datasailsDraw${uid}) = Adds draw function to list
     */
    const b64 = btoa(`
    function _datasailsDraw${uid}() {
      var options = ${JSON.stringify({ series: options.series, ...options.options })};
      if (options.yaxis && options.yaxis.labels && options.yaxis.labels.formatterConstruct) {
        var formatter = Function.apply(Function, options.yaxis.labels.formatterConstruct);
        var argsAmount = options.yaxis.labels.formatterConstruct.length - 1;
        options.yaxis.labels.formatter = function() {
          var args = [];
          for (var i = 0; i < argsAmount; i++) {
            args.push(arguments[i]);
          }
          return formatter.apply(this, args);
        };
      }
      var chart = new ApexCharts(document.getElementById('${uid}'), options);
      chart.render();
    }
    window._apexBuilders.push(_datasailsDraw${uid});
    `);

    // Add this to the src of a script, and it'll run as if it was linked to a file
    return `data:application/javascript;base64,${b64}`;
  }
  return null;
}
