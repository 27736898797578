import React from 'react';
import propTypes from 'prop-types';
import { motion } from 'framer-motion';
import { IconInfo } from '../../icons';
import { useHover } from '../../hooks';

const notificationVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export function InfoPopover({ icon, children }) {
  const [ref, isHover] = useHover();

  return (
    <div className="info-popover">
      <span className="icon has-text-info" ref={ref}>
        {icon}
      </span>
      <motion.div
        className="notification content"
        variants={notificationVariants}
        initial="hidden"
        animate={isHover ? 'visible' : 'hidden'}
      >
        {children}
      </motion.div>
    </div>
  );
}

InfoPopover.defaultProps = {
  icon: <IconInfo />
};

InfoPopover.propTypes = {
  icon: propTypes.oneOfType([
    propTypes.node,
    propTypes.element,
  ]),
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.element,
  ]).isRequired,
};
