import React from 'react';
import propTypes from 'prop-types';
import { Base } from './Base';

export function IconDropdown({ className }) {
  return (
    <Base isSmall className={className}>
      <path fillRule="evenodd" d="M9.5 5l-.1.3-3 3-.4.2-.3-.1-3-3.1c-.2-.1-.2-.2-.2-.4l.1-.3.3-.1h6.2l.3.1.1.3z" />
    </Base>
  );
}

IconDropdown.defaultProps = {
  className: '',
};

IconDropdown.propTypes = {
  className: propTypes.string,
};
