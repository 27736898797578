import React from 'react';
import { Base } from './Base';

export function IconSort() {
  return (
    <Base>
      <path fillRule="evenodd" d="M16.7 5.8l4.9 6.3c.6.8.2 2.2-.7 2.2h-9.8c-.9 0-1.3-1.4-.7-2.2l4.9-6.3c.4-.5 1-.5 1.4 0zm4.2 12h-9.8c-.9 0-1.3 1.3-.7 2l4.9 6.4c.4.5 1 .5 1.4 0l4.9-6.3c.6-.8.2-2.2-.7-2.2z" />
    </Base>
  );
}
