import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import _ from 'lodash';
import { CHART_TYPES, MAP_CHART_TYPE } from '../../core/const';
import { HALF_DROP_AREAS, ABOVE_DROP_AREAS, VERTICAL_DROP_AREAS } from '../const';
import { useSelectedCharts } from '../../core';

export function DropArea({ position, notAccepted, chart }) {
  const { setSelectedCharts, selectedCharts } = useSelectedCharts();
  const acceptedTypes = CHART_TYPES
    .filter(c => !notAccepted || c.label !== notAccepted)
    .map(c => c.label).concat([MAP_CHART_TYPE.label]);
  const [{ isOver, isActive }, drop] = useDrop({
    accept: acceptedTypes,
    drop: (item) => {
      const currentChart = notAccepted ? _.find(selectedCharts, { label: notAccepted }) : chart;
      const newCurrentChart = { ...currentChart, isHalf: false };
      const newMovedChart = { ...item.chart, isHalf: false };
      const currentChartIndex = selectedCharts.indexOf(currentChart);
      const movedChartIndex = selectedCharts.indexOf(item.chart);
      let newSelectedCharts = selectedCharts.slice();
      newSelectedCharts.splice(currentChartIndex, 1, newCurrentChart);
      newSelectedCharts.splice(movedChartIndex, 1, newMovedChart);

      if (VERTICAL_DROP_AREAS.includes(position)) {
        newCurrentChart.isHalf = currentChart.isHalf;
        let newMovedChartIndex = currentChartIndex < movedChartIndex ? Math.min(selectedCharts.length - 1, currentChartIndex + 1) : currentChartIndex;
        if (position === 'above') {
          newMovedChartIndex = currentChartIndex > movedChartIndex ? Math.max(0, currentChartIndex - 1) : currentChartIndex;
        }
        newSelectedCharts = arrayMove(
          newSelectedCharts,
          movedChartIndex,
          newMovedChartIndex
        );
      } else if (currentChart.isHalf) {
        newCurrentChart.isHalf = item.chart.isHalf === newCurrentChart.label ? item.chart.label : item.chart.isHalf;
        newMovedChart.isHalf = currentChart.isHalf === newMovedChart.label ? currentChart.label : currentChart.isHalf;

        const otherHalf = _.find(newSelectedCharts, { label: newMovedChart.isHalf });

        if (otherHalf.label !== newMovedChart.label) {
          const otherHalfIndex = newSelectedCharts.indexOf(otherHalf);
          const newOtherHalf = { ...otherHalf, isHalf: newMovedChart.label };

          newSelectedCharts.splice(otherHalfIndex, 1, newOtherHalf);
        }

        newSelectedCharts[currentChartIndex] = newMovedChart;
        newSelectedCharts[movedChartIndex] = newCurrentChart;
      } else {
        const goesAbove = ABOVE_DROP_AREAS.includes(position);
        const goesHalf = HALF_DROP_AREAS.includes(position);
        let newMovedChartIndex = currentChartIndex < movedChartIndex ? Math.min(selectedCharts.length - 1, currentChartIndex + 1) : currentChartIndex;

        if (goesAbove) {
          newMovedChartIndex = currentChartIndex > movedChartIndex ? Math.max(0, currentChartIndex - 1) : currentChartIndex;
        }

        if (goesHalf) {
          newCurrentChart.isHalf = newMovedChart.label;
          newMovedChart.isHalf = newCurrentChart.label;
        }

        newSelectedCharts = arrayMove(
          newSelectedCharts,
          movedChartIndex,
          newMovedChartIndex,
        );
      }

      /* eslint-disable no-param-reassign */
      newSelectedCharts = newSelectedCharts.map((c) => {
        if (c.isHalf) {
          const referenced = _.find(newSelectedCharts, { label: c.isHalf });
          if (referenced.isHalf !== c.label || c.label === c.isHalf) {
            c.isHalf = false;
          }
        }
        return c;
      });

      setSelectedCharts(newSelectedCharts);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      isActive: monitor.getItem() && monitor.getItem().type !== notAccepted,
    }),
  });

  return (
    <div className={classNames(`drop-area is-${position}`, { 'is-over': isOver, 'is-active': isActive })} ref={drop} />
  );
}

DropArea.defaultProps = {
  notAccepted: null,
  chart: null,
};

DropArea.propTypes = {
  notAccepted: propTypes.string,
  position: propTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  chart: propTypes.object,
};
