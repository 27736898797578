import { useDispatch, useSelector } from 'react-redux';
import { getSaving, setSaving as setReduxSaving } from '../redux';

export function useSaving() {
  const dispatch = useDispatch();
  const saving = useSelector(getSaving);

  function setSaving() {
    dispatch(setReduxSaving(true));
  }

  function setNotSaving() {
    dispatch(setReduxSaving(false));
  }

  return {
    saving,
    setSaving,
    setNotSaving,
  };
}
