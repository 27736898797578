import propTypes from 'prop-types';

export const DEFAULT_CATEGORY = JSON.parse(process.env.REACT_APP_DEFAULT_CATEGORY);

export const DATA_TYPE = propTypes.shape({
  id: propTypes.number.isRequired,
  categories: propTypes.arrayOf(propTypes.shape({
    name: propTypes.string.isRequired,
    value: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
      propTypes.arrayOf(propTypes.string),
    ]),
    type: propTypes.string.isRequired,
    currency: propTypes.string,
  })),
});

export const CATEGORIES_TYPE = propTypes.arrayOf(propTypes.shape({
  name: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  isArray: propTypes.bool.isRequired,
}));

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

export const AUTO_REBUILD_PARAM = 'autoRebuild';

export const INVESTMENT_UNDISCLOSED = 'undisclosed';
