import React, { useEffect, useMemo, useState } from "react";
import { Container, Section, Title, Columns, Column, Button } from "bloomer";
import ReactResizeDetector from "react-resize-detector";
import { DataSelector } from "./data-selector";
import { ChartPreview } from "./chart-preview";
import { useVisualization, useSaving, Spinner, DragPreview, InfoPopover, Toggle } from "./core";
import { useClipboard } from "./hooks";
import { useTitle, useVisualizationSettings } from "./core/hooks";
import { saveVisualization } from "./api";
import { IconRemove, IconShareLink, IconShare } from "./icons";
import { shouldAutoRebuild } from "./util";

if (window.dsChartColors && window.dsChartColors.length > 0) {
  window.Apex.colors = window.dsChartColors;
}

export default function App() {
  const [frameHeight, setFrameHeight] = useState(0);
  const [frameWidth, setFrameWidth] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const visualizationId = params.get("visualization");

  const { visualization, loadVisualization } = useVisualization();
  const { saving, setSaving, setNotSaving } = useSaving();
  const { title } = useTitle();
  const { copy } = useClipboard();
  const { settings } = useVisualizationSettings();
  const [loading, setLoading] = useState(true);
  const [isGenerated, setIsGenerated] = useState(false);
  const [autoRebuild, setAutoRebuild] = useState(false);

  /**
   * window._apexBuilders = Functions that recreate the charts
   * window.addEventListener('load', function() {...}) = Iterates over the functions and create the charts
   */
  const loadingScript = useMemo(
    () =>
      btoa(`
        window._apexBuilders=[];
        window.addEventListener('load', function() {
          ${
            window.Apex.colors
              ? `
            window.Apex.colors = ${JSON.stringify(window.Apex.colors)};
          `
              : ""
          }
          window._apexBuilders.forEach(function(fn) {
            fn();
          });
        });
      `),
    []
  );

  function onDiscardVisualization() {
    const message = { type: "close" };
    window.parent.postMessage(JSON.stringify(message), "*");
  }

  const handleResize = (width, height) => {
    if (saving) {
      return;
    }

    if (frameHeight !== height || frameWidth !== width) {
      const message = { type: "resize", width: 900, height: height + 75 };

      setFrameHeight(height);
      setFrameWidth(width);
      window.parent.postMessage(JSON.stringify(message), "*");
    }
  };

  async function save(content) {
    const data = {
      ...visualization,
      name: title,
      visualizationSettings: JSON.stringify(settings),
      visualizationHtml: content,
      dateModified: new Date(),
      autoRebuild: !!autoRebuild
    };

    await saveVisualization(data);
  }

  function generateHtml(saveToClipboard) {
    setSaving();
    setTimeout(async () => {
      const container = document.querySelector(".app");
      const html = container.innerHTML;

      if (saveToClipboard) {
        copy(html);
      }

      await save(html);

      setNotSaving();
      setIsGenerated(true);
    }, 1);
  }

  function onGenerateVisualization() {
    generateHtml(false);
  }

  function copyVisualizationLink() {
    copy(
      `https://viz.datasails.com?id=${
        visualization.id
      }&ver=${new Date().getTime()}`
    );
  }

  useEffect(() => {
    async function load() {
      await loadVisualization(visualizationId);
      setLoading(false);
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && shouldAutoRebuild()) {
      onGenerateVisualization();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) {
    return <Spinner />;
  }

  if (isGenerated) {
    return (
      <Section className="app">
        <Container>
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={handleResize}
          />
          <Columns isVCentered isMobile>
            <Column />
            <Column isSize="narrow">
              <Button
                type="button"
                isOutlined
                isColor="grey-light"
                isSize="small"
                onClick={onDiscardVisualization}
              >
                <span className="icon">
                  <IconRemove />
                </span>
                <span>Close</span>
              </Button>
            </Column>
          </Columns>
          <Title className="app-generated-title has-text-weight-normal">
            Your Visualization is Ready
          </Title>
          <div className="app-generated-container">
            <div className="app-generated-link">{`https://viz.datasails.com?id=${visualization.id}`}</div>
          </div>
          <div className="app-generated-buttons">
            <Button onClick={copyVisualizationLink} isColor="info">
              <span className="icon is-medium">
                <IconShareLink />
              </span>
              <span>Copy Link</span>
            </Button>
            <a
              href={`https://viz.datasails.com?id=${
                visualization.id
              }&ver=${new Date().getTime()}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button isColor="info">
                <span className="icon is-medium">
                  <IconShare />
                </span>
                <span>Open Link</span>
              </Button>
            </a>
          </div>
        </Container>
      </Section>
    );
  }

  return (
    <>
      {saving && <Spinner overlay message="Building visualization..." />}
      <DragPreview />
      <Section className="app">
        <Container>
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={handleResize}
          />
          {!saving ? (
            <>
              <Columns isVCentered isMobile>
                <Column>
                  <Title className="app-title has-text-weight-normal">
                    Visualization Editor
                  </Title>
                </Column>
                <Column isSize="narrow">
                  <Button
                    type="button"
                    isOutlined
                    isColor="info"
                    isSize="small"
                    onClick={onDiscardVisualization}
                    className="discard-vis-button"
                  >
                    <span className="icon">
                      <IconRemove />
                    </span>
                    <span>Discard visualization</span>
                  </Button>
                </Column>
              </Columns>
              <DataSelector />
            </>
          ) : (
            <>
              <link
                href={`https://static.datasails.com/colors-v${process.env.REACT_APP_VERSION}.css`}
                rel="stylesheet"
              />
              <link
                href={`https://static.datasails.com/style-v${process.env.REACT_APP_VERSION}.css`}
                rel="stylesheet"
              />
              <script
                src={`https://static.datasails.com/colors-v${process.env.REACT_APP_VERSION}.js`}
              />
              <link
                href="https://fonts.googleapis.com/css?family=Lato|Roboto+Condensed:400,700&display=swap"
                rel="stylesheet"
              />
              <script src="https://cdn.jsdelivr.net/npm/apexcharts" />
              <script
                src={`data:application/javascript;base64,${loadingScript}`}
              />
            </>
          )}

          <ChartPreview />

          {!saving && (
            <>
              <Toggle onChange={(event) => setAutoRebuild(event.target.checked)} id="autorebuild" defaultChecked={visualization.autoRebuild}>
                Update data-visualization daily
                &nbsp;
                <InfoPopover>
                  <p>Date range was selected for a number of days including today. Tick the Toggle if you would like this data-visualization to be rebuilt daily for the same number of days.</p>
                  <p>Example: if you chose ‘Last 30 days’ (from August 15 until September 15), and you tick the box, next day data-vizualization will rebuild for date-range from August 16 until September 16.</p>
                </InfoPopover>
              </Toggle>
              <div className="buttons">
                <Button
                  onClick={onGenerateVisualization}
                  isColor="info"
                  className="create-vis-button"
                >
                  <span className="icon">
                    <IconShareLink />
                  </span>
                  <span>Create Visualization</span>
                </Button>
              </div>
            </>
          )}
        </Container>
      </Section>
    </>
  );
}
