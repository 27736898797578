export const SELECTED_CHARTS_SET = 'selectedCharts/set';
export const CATEGORIES_ADD = 'categories/add';
export const CATEGORIES_REMOVE = 'categories/remove';
export const CATEGORIES_SET = 'categories/set';
export const DATA_SET = 'data/set';
export const SAVING_SET = 'saving/set';
export const TITLE_SET = 'title/set';
export const VISUALIZATION_SET = 'visualization/set';

export function setSelectedCharts(charts) {
  return {
    type: SELECTED_CHARTS_SET,
    payload: charts,
  };
}

export function addCategory(category) {
  return {
    type: CATEGORIES_ADD,
    payload: category,
  };
}

export function removeCategory(category) {
  return {
    type: CATEGORIES_REMOVE,
    payload: category,
  };
}

export function setCategories(categories) {
  return {
    type: CATEGORIES_SET,
    payload: categories,
  };
}

export function setData(data) {
  return {
    type: DATA_SET,
    payload: data,
  };
}

export function setSaving(isSaving) {
  return {
    type: SAVING_SET,
    payload: isSaving,
  };
}

export function setTitle(title) {
  return {
    type: TITLE_SET,
    payload: title,
  };
}

export function setVisualization(visualization) {
  return {
    type: VISUALIZATION_SET,
    payload: visualization,
  };
}
