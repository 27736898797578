import React from 'react';

/**
 * Add asterisks to a string based on split or partially included projects
 *
 * @export
 * @param {String} name project name or other string to add asterisks to
 * @param {Boolean} isSplit wether it is multinational
 * @param {Boolean} isPartiallyIncluded wether it is included in only some categories
 * @returns {String}
 */
export function addAsterisks(name, isSplit, isPartiallyIncluded) {
  const asterisks = [isSplit && '1', isPartiallyIncluded && '2'].filter(Boolean).join(', ');
  return (
    <>
      {/* `${name}${asterisks ? ` ${asterisks}` : ''}` */}
      {name}
      {asterisks ? <sup className="asterisks-sup">&nbsp;{asterisks}</sup> : null}
    </>
  );
}