import React, { useMemo, useRef, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Columns, Column } from 'bloomer';
import { CHART_PROP_TYPES } from '../const';
import { dotPlotDataBuilder, lerp, currencyFormatUnits, dateFormat, addAsterisks } from '../../util';
import { useScrollableChart } from '../../hooks';

const DOT_MIN_SIZE = 10;
const DOT_MAX_SIZE = 40;
const MIN_CELL_WIDTH = 160;
const MIN_CELL_HEIGHT = 49;
const CHART_AREA_HEIGHT = 400 - 74;

function cellLabelBuilder(cellData) {
  if (cellData.length > 9) {
    return '9+ projects';
  }
  if (cellData.length > 1) {
    return `${cellData.length} projects`;
  }
  return cellData[0].label;
}

export function DotPlotChart({ categories, data }) {
  const dotPlotData = useMemo(() => dotPlotDataBuilder(categories, data), [categories, data]);
  const viewportParent = useRef(null);
  const cornerCoverRef = useRef(null);
  const { uid, saving, scriptSrc } = useScrollableChart();

  const onScroll = useCallback(() => {
    const { current: viewport } = viewportParent;
    const { current: cornerCover } = cornerCoverRef;
    const { scrollTop, scrollLeft } = viewport;

    cornerCover.style.bottom = `${scrollTop * -1}px`;
    cornerCover.style.left = `${scrollLeft}px`;
  }, []);

  let cellHeight = CHART_AREA_HEIGHT / (dotPlotData?.data?.length || 8);

  if(cellHeight < MIN_CELL_HEIGHT) {
    cellHeight = MIN_CELL_HEIGHT;
  }

  const columnCount = dotPlotData?.data[0]?.cells?.length || 1;
  const columnWidth = `max(${MIN_CELL_WIDTH}px, calc(100% / ${columnCount}))`;

  const rowWidth = `calc(${columnWidth} * ${columnCount})`;
  const rowWidthXAxis = `calc(${columnWidth} * ${columnCount} ${columnCount < 5 ? '' : '+ 100px'})`;
  const chartHeight = cellHeight * dotPlotData?.data?.length || 0;

  useEffect(() => {
    const { current: viewport } = viewportParent;
    if (viewport) {
      viewport.addEventListener('scroll', onScroll);
    }

    return () => viewport.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <>
      <div className="scrollable-chart dot-plot-chart" ref={viewportParent} id={uid} name="DotPlot">
        <div className="chart-top" style={{ width: rowWidth, height: chartHeight }}>
          <div className="chart-yaxis" style={{ height: chartHeight }}>
            {dotPlotData.aggregators.map(aggregator => (
              <div className="yaxis-row-label" key={aggregator} style={{height: cellHeight}}>
                <div className="yaxis-row-label-inner">{aggregator}</div>
              </div>
            ))}
          </div>
          <div className={classNames('chart-area', { 'has-more-than-four-rows': dotPlotData.data.length > 4 })} style={{ height: chartHeight, width: rowWidth }}>
            {dotPlotData.data.map(row => (
              <div className="chart-row" key={row.label} style={{height: cellHeight}}>
                {row.cells.map(cell => {
                  const ratio = cell.value / dotPlotData.max;
                  const dotSize = lerp(DOT_MIN_SIZE, DOT_MAX_SIZE, ratio);
                  return (
                    <div className="chart-cell" key={cell.aggregator2} style={{width: columnWidth}}>
                      {dotSize > DOT_MIN_SIZE && (
                        <>
                          <div className={classNames('cell-dot-wrapper', { 'has-value-below': ratio < 0.6 })}>
                            <div
                              className="cell-dot"
                              style={{
                                width: dotSize,
                                height: dotSize,
                              }}
                            />
                            <span className="cell-value">
                              {addAsterisks(currencyFormatUnits(cell.value), cell.isSplit, false)}
                            </span>
                          </div>
                          <div className="cell-label">
                            {cellLabelBuilder(cell.data)}
                          </div>
                          <div className="cell-popover">
                            <Columns>
                              <Column isSize="narrow">
                                <div className={classNames('cell-dot-wrapper', { 'has-value-below': ratio < 0.6 })}>
                                  <div
                                    className="cell-dot"
                                    style={{
                                      width: dotSize,
                                      height: dotSize,
                                    }}
                                  />
                                  <span className="cell-value">
                                    {addAsterisks(currencyFormatUnits(cell.value), cell.isSplit, false)}
                                  </span>
                                </div>
                              </Column>
                              <Column>
                                {cell.data.map(project => {
                                  const formattedDate = project.date ? dateFormat(project.date, 'MMM dd. yyyy') : 'Date N/A';

                                  return (
                                    <p key={project.label}>
                                      <b>{project.label}</b>
                                      <br />
                                      {cell.data.length > 1 ? (
                                        <>
                                          {addAsterisks(currencyFormatUnits(project.value), project.isSplit, false)}
                                          {`, ${formattedDate}`}
                                        </>
                                      ) : (formattedDate)}
                                    </p>
                                  );
                                })}
                              </Column>
                            </Columns>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
        <div className="chart-xaxis" style={{ width: rowWidthXAxis }}>
          {dotPlotData.aggregators2.map(aggregator2 => (
            <div className="xaxis-column-label" key={aggregator2} style={{width: columnWidth, maxWidth: columnWidth}}>{aggregator2}</div>
          ))}
        </div>
        <div className="corner-cover" ref={cornerCoverRef} />
      </div>
      {saving && <script src={scriptSrc} />}
    </>
  );
}

DotPlotChart.propTypes = CHART_PROP_TYPES;
