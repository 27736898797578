import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCharts, setSelectedCharts as reduxSetSelectedCharts } from '../redux';

export function useSelectedCharts() {
  const dispatch = useDispatch();
  const selectedCharts = useSelector(getSelectedCharts);

  function setSelectedCharts(charts) {
    return dispatch(reduxSetSelectedCharts(charts));
  }

  return {
    selectedCharts,
    setSelectedCharts,
  };
}