export function currencyFormatUnits(value, currency = 'USD', locale = 'en-US', options = {}, separateUnitWithSpace = true) {
  const SI_SYMBOL = ['', 'k', 'M', 'B', 'T'];
  let valueToFormat = value;
  // eslint-disable-next-line no-bitwise
  const tier = Math.log10(value) / 3 | 0;

  const defaultOptions = {
    style: 'currency',
    currency,
    useGrouping: false,
  };
  let formatter;
  let suffix = 'M';

  if (tier > 1) {
    suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
  
    valueToFormat /= scale;
  } else {
    valueToFormat /= 1000000;
  }

  if (valueToFormat >= 100) {
    formatter = Intl.NumberFormat(locale, {
      ...defaultOptions,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options,
    });
  } else if (valueToFormat >= 10) {
    formatter = Intl.NumberFormat(locale, {
      ...defaultOptions,
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      ...options,
    });
  } else {
    formatter = Intl.NumberFormat(locale, {
      ...defaultOptions,
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
      ...options,
    });
  }

  return `${formatter.format(valueToFormat)}${separateUnitWithSpace ? ' ' : ''}${suffix}`;
}