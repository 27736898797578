export function isEmpty(value) {
  let result = false;

  if (value === null) {
    result = true;
  } else if (value === '') {
    result = true;
  } else if (typeof value === 'undefined') {
    result = true;
  } else if (Array.isArray(value) && value.length < 1) {
    result = true;
  } else if (Number.isNaN(value)) {
    result = true;
  } else if (typeof value === 'object' && Object.keys(value).length < 1) {
    result = true;
  }

  return result;
}