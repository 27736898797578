import React from 'react';
import { Base } from './Base';

export function IconShareLink() {
  return (
    <Base>
      <path fillRule="evenodd" d="M18.5 13c-.4-.4-1-.8-1.5-1-.3-.2-.5-.1-.7 0l-.1.2a2 2 0 00-.7 1.3c0 .3.1.5.3.6l1 .7a3.7 3.7 0 010 5.2l-3.3 3.3c-1.4 1.4-3.8 1.4-5.2 0s-1.4-3.8 0-5.2l2-2c.1-.1.2-.3.1-.5l-.2-1.7c0-.4-.5-.6-.9-.3l-2.7 2.7a6.1 6.1 0 000 8.7 6.1 6.1 0 008.7 0c3.5-3.6 3.4-3.4 3.6-3.8 2-2.3 1.9-5.9-.4-8.1zm6.9-6.8a6.1 6.1 0 00-8.7 0L13 10a6.1 6.1 0 002 9.3c.2.1.4 0 .6-.1l.2-.2c.4-.3.6-.8.6-1.3 0-.2 0-.4-.2-.6-.3-.1-.8-.4-1-.7a3.7 3.7 0 010-5.2L18.5 8c1.4-1.4 3.7-1.4 5.2 0s1.4 3.8 0 5.2l-2 2c-.2.2-.2.4-.2.5l.2 1.7c0 .4.6.7 1 .4.4-.6 1.3-1.4 2.7-2.8a6.1 6.1 0 000-8.7z" />
    </Base>
  );
}
