import {
  SELECTED_CHARTS_SET,
  CATEGORIES_ADD,
  CATEGORIES_REMOVE,
  DATA_SET,
  SAVING_SET,
  TITLE_SET,
  VISUALIZATION_SET,
  CATEGORIES_SET
} from "./actions";
import { DEFAULT_CATEGORY } from "../../const";
import { filterSelectedCharts } from "../../util";
import { CHART_TYPES, MAP_CHART_TYPE } from "../const";

const initialState = {
  selectedCharts: [],
  categories: [DEFAULT_CATEGORY],
  data: [],
  saving: false,
  title: "",
  visualization: {}
};

export function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SELECTED_CHARTS_SET:
      return {
        ...state,
        selectedCharts: action.payload.map((chart) => {
          if (action.payload.length === 1) {
            // eslint-disable-next-line no-param-reassign
            chart.isHalf = false;
          }
          return chart;
        })
      };
    case CATEGORIES_ADD:
      return {
        ...state,
        categories: state.categories.concat([action.payload])
      };
    case CATEGORIES_REMOVE:
      // eslint-disable-next-line no-case-declarations
      const categories = state.categories.filter(
        category => category !== action.payload
      );
      return {
        ...state,
        categories,
        selectedCharts: filterSelectedCharts(state.selectedCharts, categories, state.data)
      };
    case CATEGORIES_SET:
      return {
        ...state,
        categories: action.payload,
        selectedCharts: filterSelectedCharts(state.selectedCharts, action.payload, state.data)
      };
    case DATA_SET:
      return {
        ...state,
        data: action.payload
      };
    case SAVING_SET:
      return {
        ...state,
        saving: action.payload
      };
    case TITLE_SET:
      return {
        ...state,
        title: action.payload
      };
    case VISUALIZATION_SET: {
      let settings = {};

      if (action.payload.visualizationSettings) {
        settings = JSON.parse(action.payload.visualizationSettings);
      }

      const selectedCharts = Array.isArray(settings.selectedCharts)
        ? settings.selectedCharts.map(selectedChart => ({
          ...(CHART_TYPES).concat(MAP_CHART_TYPE).find(type => type.label === selectedChart.label),
        }))
        : state.selectedCharts;

      return {
        ...state,
        visualization: action.payload,
        selectedCharts,
        categories: settings.categories || state.categories,
        title: action.payload.name
      };
    }
    default:
      return state;
  }
}
