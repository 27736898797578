import propTypes from 'prop-types';
import { DATA_TYPE, CATEGORIES_TYPE } from '../const';

export const CHART_PROP_TYPES = {
  data: propTypes.arrayOf(DATA_TYPE).isRequired,
  categories: CATEGORIES_TYPE.isRequired,
};

export const HIDDEN_CLASS = 'is-hidden';

export const GEO_MAX_ZOOM = 12;