/**
 * Simple SVG collision detection algorithm
 * 
 * @see https://www.inkfood.com/collision-detection-with-svg/
 * @param {*} r1 svg.js bounding box for object 1
 * @param {*} r2 svg.js bounding box for object 2
 */
export function intersectRect(r1, r2) {
  return !(r2.x > r1.x2 ||
    r2.x2 < r1.x ||
    r2.y > r1.y2 ||
    r2.y2 < r1.y);
}