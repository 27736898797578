import { useDispatch, useSelector } from "react-redux";
import {
  getVisualization as getReduxData,
  setVisualization as setReduxData
} from "../redux";
import { getVisualization } from "../../api";
import {useData} from "./useData";

export function useVisualization() {
  const dispatch = useDispatch();
  const visualization = useSelector(getReduxData);
  const { setData } = useData();

  function setVisualization(newData) {
    return dispatch(setReduxData(newData));
  }

  async function loadVisualization(visualizationId) {
    const result = await getVisualization(visualizationId);
    await setVisualization(result.visualization);
    await setData(result.data);
    return result.visualization;
  }

  return {
    visualization,
    setVisualization,
    loadVisualization
  };
}
