import { HIDDEN_CLASS } from "../../charts/const";
import { intersectRect } from "./intersectRect";

export function hideOverlappingElements(textGroup, bubbleGroup, countriesGroup) {
  const elements = textGroup.children().concat(bubbleGroup.children());
  if (elements.length > 1) {
    elements.forEach((element) => {
      if (!element.hasClass(HIDDEN_CLASS)) {
        const id = element.attr('data-id');
        const elementPath = countriesGroup.findOne(`[data-id=${id}]`);
        const elementPathBbox = elementPath.bbox();
        const elementPathSize = elementPathBbox.height + elementPathBbox.width;
        for (let i = 0; i < elements.length; i += 1) {
          const compareElement = elements[i];
          const compareElementId = compareElement.attr('data-id');
          const compareElementPath = countriesGroup.findOne(`[data-id=${compareElementId}]`);
          const compareElementPathBbox = compareElementPath.bbox();
          const compareElementPathSize = compareElementPathBbox.height + compareElementPathBbox.width;
          const isSameElement = compareElement === element;
          const isSameId = compareElementId === id;
          const isHidden = compareElement.hasClass(HIDDEN_CLASS);
          const isIntersecting = intersectRect(element.bbox(), compareElement.bbox());

          if (!isSameElement && !isSameId && !isHidden && isIntersecting) {
            const query = `[data-id=${compareElementPathSize > elementPathSize ? id : compareElementId}]`;
            bubbleGroup.find(query).addClass(HIDDEN_CLASS);
            textGroup.find(query).addClass(HIDDEN_CLASS);
            break;
          }
        }
      }
    });
  }
}