import React, { useEffect, useState } from "react";
import { usePreview } from "react-dnd-preview";

/*
This component is used to display the ghost image while dragging the charts around by
displaying a copy of the DOM node that's being dragged in a popup window.

We're doing it manually since we're using react-dnd-touch-backend as react-dnd backend
to avoid issues with absolutely positioned elements: https://github.com/react-dnd/react-dnd/issues/1492
*/

const chartName = {
  "Dot Plot": "DotPlot",
  Table: "TableChart",
  Map: "GeoChart",
  Bars: "BarChart",
  Scatter: "ScatterChart",
  Stacked: "StackedChart",
  "on Map": "GeoChart"
};

export function DragPreview() {
  const { display, style, itemType, item } = usePreview();
  const [dimensions, setDimensions] = useState({ height: 400, width: 400 });
  const [width, setWidth] = useState(162);

  useEffect(() => {
    if (item) {
      const source = document.getElementsByName(chartName[itemType])[0];

      if (item.chart) {
        const preview = document.getElementsByClassName("drag-preview")[0];
        if (preview) {
          preview.innerHTML = "";

          if (source) {
            setDimensions({ width: source.offsetWidth, height: source.offsetHeight });
            const newNode = source.cloneNode(true);
            newNode.style.width = `${source.offsetWidth}px`;
            newNode.style.height = `${source.offsetHeight}px`;
            preview.appendChild(newNode);
          }
        }
      } else if (source) {
        setWidth(source.offsetWidth);
      }
    }
  }, [itemType, display, item]);

  if (!display) {
    return null;
  }

  if (item.chart) {
    return (
      <div
        className="drag-preview"
        style={{
          ...style,
          ...dimensions,
          zIndex: 99999,
          opacity: 0.7
        }}
      />
    );
  }

  return (
    <div
      className="drag-preview"
      style={{
        ...style,
        zIndex: 99999,
        opacity: 0.7
      }}
    >
      {item && (
        <div className="tag has-text-weight-bold" style={{ width }}>
          {item.type}
        </div>
      )}
    </div>
  );
}
