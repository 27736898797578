import { SVG } from "@svgdotjs/svg.js";
import _ from "lodash";
import { REGIONS } from "../../api/regions";

export function createRegionElements({
  regions,
  regionsGroup,
  regionTextGroup,
  regionBubbleGroup,
  mouseHover,
  onClickRegion,
}) {
  const maxRegionValue = regions.length > 0 && regions.every(region => region.value !== null) ? _.maxBy(regions, 'value.v').value.v : 0;

  regions.filter(region => region.name !== REGIONS.WRL.name).forEach(region => {
    const { cx, cy } = regionsGroup.findOne(`[data-id="${region.name}"]`).bbox();

    // Create region middle text
    const text = SVG().text(region.name);
    text.font({
      family: 'Lato',
      size: '12px',
      anchor: 'middle',
      leading: '1em',
      weight: 700,
    })
      .attr('data-id', region.name)
      .attr('data-type', 'title')
      .attr('vector-effect', 'non-scaling-size')
      .addTo(regionTextGroup)
      .on('mouseenter', () => mouseHover(region.name))
      .on('mouseleave', () => mouseHover())
      .click(function onClick() { onClickRegion(region.name) })
      .center(cx, cy);

      if (region.value !== null) {
        // Create region investment bubble
        const diameter = ((region.value.v / maxRegionValue) * (400 / 50 * 10));
        const circle = SVG().circle(
          diameter !== Math.Infinity ? diameter : 0
        );
        circle
          .attr('data-id', region.name)
          .addTo(regionBubbleGroup)
          .on('mouseenter', () => mouseHover(region.name))
          .on('mouseleave', () => mouseHover())
          .click(function onClick() { onClickRegion(region.name) })
          .center(cx, cy);
        
        // Update region middle text with value and center in circle
        const { y: circleY, height: circleHeight } = circle.bbox();
        text
          .center(cx, circleY + (circleHeight / 2))
          .attr('data-type', 'value')
          .text(region.value.f);

        // Create another text for region name
        const regionName = SVG().text(region.name);
        regionName
          .font({
            family: 'Lato',
            size: '14px',
            anchor: 'middle',
            leading: '1em',
            weight: 700,
          })
          .attr('data-id', region.name)
          .attr('data-type', 'title')
          .attr('vector-effect', 'non-scaling-size')
          .addTo(regionTextGroup)
          .on('mouseenter', function mouseEnter() { mouseHover(region.name); })
          .on('mouseleave', function mouseLeave() { mouseHover(); })
          .click(function onClick() { onClickRegion(region.name) })
          .center(cx, cy + 5);
      }
  });
}