import React from 'react';
import { Base } from './Base';

export function IconDotPlotChart() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M1.6 0H0v32h48v-1.6H1.6V0zm33.6 12.8a3.2 3.2 0 100-6.4 3.2 3.2 0 000 6.4zm9.6-3.2a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zm-36 1.6a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zM20 9.6a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zm5.6 4a4 4 0 100-8 4 4 0 000 8zm10.8 8a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0zm6 2.4a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8zm-23.2-2.4a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0zM8.8 24a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8zm17.6-2.4a.8.8 0 11-1.6 0 .8.8 0 011.6 0z" />
    </Base>
  );
}
