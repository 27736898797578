/* eslint-disable */
import React, { useMemo } from "react";
import { format } from "date-fns";
import classNames from "classnames";
import { Columns, Column } from "bloomer";
import { min, max } from "lodash";
import { CHART_PROP_TYPES } from "../const";
import { scatterChartDataBuilder, currencyFormat, addAsterisks } from "../../util";
import { BOTTOM_EXTRA_HEIGHT, isGapYear } from "../../util/scatterChartDataBuilder";

const MIN_DOT_SIZE = 8;
const MAX_DOT_SIZE = 20;
const MIN_COL_WIDTH = '160px';

const getDotStyle = (value, minValue, maxValue) => {
  const valueDiff = maxValue - minValue;
  const dotSizeDiff = MAX_DOT_SIZE - MIN_DOT_SIZE;
  const diff = value - minValue;
  const dotSize = MIN_DOT_SIZE + (diff / valueDiff) * dotSizeDiff;

  return {
    width: dotSize,
    height: dotSize,
    marginTop: 16 - (dotSize - MIN_DOT_SIZE) / 2,
    marginLeft: -4 - (dotSize - MIN_DOT_SIZE) / 2
  };
};

export function ScatterChart({ categories, data }) {
  const { columns, chartHeight, ...scatterData } = useMemo(() => scatterChartDataBuilder(categories, data), [
    categories,
    data
  ]);

  const hasYAxis = false; // !!scatterData.investment;

  const chartClassName = classNames("scrollable-chart scatter-chart", {
    "no-yaxis": !hasYAxis
  });

  let columnWidth = null;

  if (scatterData.years.length - 1 > 0) {
    columnWidth = `calc((100% + ${MIN_COL_WIDTH}) / ${scatterData.years.filter(year => !isGapYear(year)).length})`;
  }

  const minInvestment = scatterData.investment ? min(scatterData.investment.map(i => i[0])) : 0;
  const maxInvestment = scatterData.investment ? max(scatterData.investment.map(i => i[0])) : 0;

  return (
    <>
      <div className={chartClassName} name="ScatterChart">
        <div className="chart-top" style={{ height: chartHeight }}>
          {hasYAxis && scatterData.investment && (
            <div className="chart-yaxis" style={{ height: chartHeight }}>
              {scatterData.investment.map(investment => (
                <div className="yaxis-row-label" key={investment[0]}>
                  {investment[1]}
                </div>
              ))}
            </div>
          )}
          <div
            className="chart-area"
            style={{ height: chartHeight }}
          >
            {columns.map(([year, projects]) => {
              const isGap = isGapYear(year);
              return (
                <div
                  className={classNames('chart-column', { 'is-gap': isGap })}
                  key={year}
                  style={{ width: isGap ? 20 : columnWidth }}
                >
                  {projects.map((project) => (
                    <div
                      className={classNames('cell-dot-wrapper', {
                        'is-up': project.top / chartHeight > 0.8,
                      })}
                      key={`${project.name}${project.date}`}
                      style={{ top: project.top, left: `${project.left}%` }}
                    >
                      <div
                        className="cell-dot"
                        style={scatterData.investment && getDotStyle(
                          project.investment,
                          minInvestment,
                          maxInvestment
                        )}
                      />
                      <div className="cell-label">{project.name}</div>
                      {scatterData.investment && (
                        <div className="cell-label cell-label-amount">
                          {addAsterisks(currencyFormat(
                            project.investment,
                            "USD",
                            "en-US",
                            {
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0
                            }
                          ), project.isSplit, false)}
                        </div>
                      )}
                      <div
                        className="dot-crosshair-left"
                      />
                      <div
                        className="dot-crosshair-bottom"
                        style={{ height: chartHeight - project.top + BOTTOM_EXTRA_HEIGHT - 62 }}
                      />
                      <div className="cell-text">
                        <Columns isVCentered>
                          <Column>
                            <b>{project.name}</b>
                          </Column>
                          <Column isSize="narrow">
                            {format(project.date, "MM-dd")}
                          </Column>
                        </Columns>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="chart-xaxis">
            {scatterData.years.map(year => {
              const isGap = isGapYear(year);

              return (
                <div
                  className={classNames('xaxis-column-label', { 'is-gap': isGap })}
                  key={year}
                  style={{ width: isGap ? 20 : columnWidth }}
                >
                  {!isGap ? year : <>&hellip;</>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

ScatterChart.propTypes = CHART_PROP_TYPES;
