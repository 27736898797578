import { parse, compareAsc } from "date-fns";
import _ from "lodash";
import { DEFAULT_DATE_FORMAT } from "../const";
import { currencyFormat } from "./currencyFormat";

export function stackedChartDataBuilder(allCategories, data) {
  const categories = [
    allCategories.find(category =>
      ["string", "country"].includes(category.type)
    ),
    allCategories.find(category => ["date"].includes(category.type)),
    allCategories.find(category =>
      ["number", "currency"].includes(category.type)
    )
  ];

  const categoriesNames = new Set();
  const dates = new Set();

  // Add all countries
  data.forEach(row => {
    const rowCountries = row.categories.find(
      category =>
        ["string", "country"].includes(category.type) &&
        _.find(categories, { name: category.name })
    );
    const rowDate = row.categories.find(
      category =>
        category.type === "date" && _.find(categories, { name: category.name })
    );

    if (rowCountries) {
      // countries or string
      if (Array.isArray(rowCountries.value)) {
        rowCountries.value.forEach(country => categoriesNames.add(country));
      } else {
        categoriesNames.add(rowCountries.value);
      }
    }

    if (rowDate && rowDate.value) {
      dates.add(
        parse(rowDate.value, DEFAULT_DATE_FORMAT, new Date()).valueOf()
      );
    }
  });

  let series = Array.from(categoriesNames).map(category => ({
    name: category,
    data: Array.from(dates)
      .sort()
      .map(date => ({
        x: date,
        y: 0
      })),
    sum: 0
  }));

  data.forEach(row => {
    const rowCategories = categories.map(category =>
      _.find(row.categories, { name: category.name })
    );
    const rowCountries = rowCategories.find(category =>
      ["string", "country"].includes(category.type)
    );
    const rowNumber = rowCategories.find(category =>
      ["number", "currency"].includes(category.type)
    );
    const rowDate = rowCategories.find(category => category.type === "date");

    if (rowCountries && rowNumber && rowDate && rowDate.value) {
      const date = parse(
        rowDate.value,
        DEFAULT_DATE_FORMAT,
        new Date()
      ).valueOf();

      let allValues = null;

      if (Array.isArray(rowCountries.value)) {
        allValues = rowCountries.value;
      } else {
        allValues = [rowCountries.value];
      }

      allValues.forEach(country => {
        const countrySeries = _.find(series, { name: country });
        const value = _.find(countrySeries.data, { x: date });

        value.y = Number((value.y + rowNumber.value).toFixed(2));
        countrySeries.sum += rowNumber.value;
      });
    }
  });

  series = _.sortBy(
    series.filter(({ sum }) => sum > 0),
    "sum"
  );
  series.forEach(countrySeries =>
    countrySeries.data.sort((a, b) => compareAsc(a.x, b.x))
  );

  return {
    options: {
      stroke: {
        width: 0
      },
      fill: {
        type: "solid"
      },
      chart: {
        stacked: true,
        type: "area",
        animations: {
          enabled: false
        },
        toolbar: false,
        height: "400px"
      },
      grid: {
        borderColor: "#fff",
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter(value) {
            return currencyFormat(value);
          },
          type: currencyFormat
        }
      },
      legend: {
        onItemClick: {
          toggleDataSeries: true
        }
      },
      dataLabels: {
        enabled: false
      }
    },
    series
  };
}
