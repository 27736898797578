import React from 'react';
import { Base } from './Base';

export function IconRemove() {
  return (
    <Base>
      <path fillRule="evenodd" d="M24.2 21.7l.2.5c0 .2 0 .3-.2.4l-1.7 1.6c0 .2-.2.2-.3.2-.2 0-.4 0-.5-.2L16 18.5l-5.7 5.7c-.1.2-.3.2-.4.2-.2 0-.3 0-.4-.2l-1.7-1.6-.2-.4c0-.2 0-.3.2-.5l5.8-5.7-5.8-5.7c-.3-.3-.3-.6 0-.9l1.6-1.6.4-.2c.2 0 .4 0 .5.2l5.7 5.7 5.7-5.7c.2-.2.3-.2.5-.2.1 0 .3 0 .4.2l1.6 1.6c.3.3.3.6 0 .9L18.4 16l5.8 5.7z" />
    </Base>
  );
}
