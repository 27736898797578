import React from 'react';
import propTypes from 'prop-types';
import { Control, Field } from 'bloomer';

export function Toggle({ id, name, onChange, children, defaultChecked }) {
  return (
    <Field className="toggle">
      <Control>
        <label htmlFor={id} className="checkbox">
          <input type="checkbox" id={id} name={name} onChange={onChange} defaultChecked={defaultChecked} />
          <span className="toggle-body" />
          <span>
            {children}
          </span>
        </label>
      </Control>
    </Field>
  );
}

Toggle.defaultProps = {
  onChange: () => {},
  id: '',
  name: '',
  defaultChecked: false,
};

Toggle.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.element,
  ]).isRequired,
  onChange: propTypes.func,
  id: propTypes.string,
  name: propTypes.string,
  defaultChecked: propTypes.oneOfType([
    propTypes.string,
    propTypes.bool,
  ]),
};
