import React from 'react';
import { Base } from './Base';

export function IconBarChart() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M0 0h1.6v30.4H48V32H0V0zm44.6 7.9h-40V4h40v3.9zm-40 6.4h28.6v-3.9H4.6v4zM26 20.8H4.6v-4H26v4zm-7 6.4H4.9v-3.9h14.3v3.9z" />
    </Base>
  );
}
