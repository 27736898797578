import { createStore } from 'redux';

import { reducer } from './reducers';

export const store = createStore(
  reducer,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export { addCategory, removeCategory, setSelectedCharts, setData, setSaving, setTitle, setVisualization, setCategories } from './actions';
export { getCategories, getSelectedCharts, getData, getSaving, getTitle, getVisualization, getSettings } from './selectors';
