import { SVG } from "@svgdotjs/svg.js";

export function createRefs(containerRef) {
  const svg = SVG(containerRef.current);
  const countriesGroup = svg.findOne("g#countries");
  const regionsGroup = svg.findOne("g#regions");
  const bubbleGroup = svg.group("bubbles");
  bubbleGroup.attr("id", "bubbles");
  const textGroup = svg.group("text");
  textGroup.attr("id", "text");
  const regionBubbleGroup = svg.group("regionBubbles");
  regionBubbleGroup.attr("id", "region-bubbles");
  const regionTextGroup = svg.group("regionText");
  regionTextGroup.attr("id", "region-text");
  const viewportsGroup = svg.findOne('g#viewports');

  return {
    map: svg,
    countriesGroup,
    regionsGroup,
    bubbleGroup,
    textGroup,
    regionBubbleGroup,
    regionTextGroup,
    viewportsGroup,
  };
}