export const getDataFORK = async (visualization, data) => {
  return data.map((item, index) => {
    return {
      id: index,
      isMultinational: false,
      categories: [
        { name: "Product", type: "string", value: item.product },
        { name: "Total", type: "currency", value: item.total },
        { name: "Customer", type: "string", value: item.customer },
        { name: "City", type: "string", value: item.city },
        { name: "Date", type: "date", value: item.date },
        { name: "Country", type: "country", value: [item.country] },
      ]
    };
  });
};

export const getVisualizationFORK = async (visualization, data, visualizationSource) => {
  const visualizationModel = {
    id: visualization.id,
    name: visualization.name,
    filter: visualization.filter,
    filterCountries: [],
    filterRegions: [],
    visualizationSettings: visualization.visualization.visualization_settings,
    visualizationHtml: visualization.visualization.visualization_html,
    dateCreated: visualization.date_created,
    dateModified: visualization.date_modified,
    dateDataUpdated: visualization.date_data_updated,
    source: `${visualizationSource}`,
    autoRebuild: visualization.auto_rebuild
  }

  return {
    visualization: visualizationModel,
    data: await getDataFORK(visualizationModel, data,)
  };
};
