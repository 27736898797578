import React, { useMemo } from "react";
import { Box, Columns, Column } from "bloomer";
import classNames from "classnames";
import { VisualizationTitle } from "./components/VisualizationTitle";
import {
  useData,
  useCategories,
  useSelectedCharts,
  useVisualization
} from "../core";
import { ChartContainer } from "./components/ChartContainer";
import { IconCopyright } from "../icons";
import { listFormat } from "../util";

export function ChartPreview() {
  const { visualization } = useVisualization();
  const { data } = useData();
  const { categories } = useCategories();
  const { selectedCharts } = useSelectedCharts();

  const possibleSplitCategories = useMemo(() => {
    if (categories.some(category => category.name === 'Investment')) {
      const aggregatorCategories = categories.filter(category => category.isArray);
      if (aggregatorCategories.length < 1) {
        return null;
      }

      return listFormat(aggregatorCategories.map(category => category.name === 'Location' ? 'country' : category.name.toLowerCase()));
    }
    return null;
  }, [categories]);

  return (
    <Box className="chart-preview">
      <VisualizationTitle data={data} />
      <div
        className={classNames('columns is-multiline layout', { "is-empty": selectedCharts.length < 1 })}
      >
        {selectedCharts.map((chart) => (
          <ChartContainer chart={chart} categories={categories} data={data} key={chart.label} />
        ))}
      </div>
      {selectedCharts.length > 0 && possibleSplitCategories ? (
        <div className="asterisks-explanations">
          <p><em>1. investment is divided amongst more than one {possibleSplitCategories}, from which all are included in totals</em></p>
          <p><em>2. investment is divided amongst more than one country, from which some are not included in totals</em></p>
        </div>
      ) : null}
        
      <Columns className="chart-footer">
        <Column className="copyright">
          <img src="https://static.datasails.com/ews-logo.svg" alt="EWS" />
          <div className="columns is-gapless">
            <p className="column is-narrow sources">
              Source:&nbsp;
            </p>
            <div className="column source-link">
              <a
                href={visualization.source}
                target="_blank"
                rel="noopener noreferrer"
              >
                {visualization.source}
              </a>
            </div>
          </div>
        </Column>
        <Column isSize="narrow" className="data-sails">
          <img src="https://static.datasails.com/made-with-ds.svg" alt="Made with DataSails" />
          <p>
            <span className="icon">
              <IconCopyright />
            </span>
            IAP 2020
            <img src="https://static.datasails.com/icon-license.svg" alt="License" />
            <span className="app-version">v{process.env.REACT_APP_VERSION}</span>
          </p>
        </Column>
      </Columns>
    </Box>
  );
}
