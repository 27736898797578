import { useState } from "react";

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    return successful;
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export function useClipboard() {
  const [copied, setCopied] = useState(false);

  async function copy(text) {
    setCopied(false);

    let result = false;

    // always do the fallback since we can't access navigator.clipboard
    // from withing iframe
    if (!navigator.clipboard || navigator.clipboard) {
      result = fallbackCopyTextToClipboard(text);
    } else {
      try {
        await navigator.clipboard.writeText(text);

        result = true;
      } catch (error) {
        console.error("Async: Could not copy text: ", error);
        result = false;
      }
    }

    setCopied(result);
    return result;
  }

  return { copy, copied };
}
