export const getDataDEMO1 = async (visualization, data) => {
  return data.map((item, index) => {
    return {
      id: index,
      isMultinational: false,
      categories: [
        { name: "Industry", type: "string", value: item.industry },
        { name: "Company", type: "string", value: item.company },
        { name: "Country", type: "country", value: [item.country] },
        { name: "Revenue", type: "currency", value: item.revenue },
        { name: "Employees", type: "number", value: item.employees },
        { name: "Best Revenue Year", type: "number", value: item.bestYrRevenue },
      ]
    };
  });
};

export const getVisualizationDEMO1 = async (visualization, data, visualizationSource) => {
  const visualizationModel = {
    id: visualization.id,
    name: visualization.name,
    filter: visualization.filter,
    filterCountries: [],
    filterRegions: [],
    visualizationSettings: visualization.visualization_settings,
    visualizationHtml: visualization.visualization_html,
    dateCreated: visualization.date_created,
    dateModified: visualization.date_modified,
    dateDataUpdated: visualization.date_data_updated,
    source: `${visualizationSource}`,
    autoRebuild: visualization.auto_rebuild
  }

  return {
    visualization: visualizationModel,
    data: await getDataDEMO1(visualizationModel, data,)
  };
};
