import { useState, useCallback, useEffect } from 'react';

export function useDropdown(initialState = false) {
  const [isActive, setIsActive] = useState(initialState);

  const setActive = useCallback(() => {
    setIsActive(true);
  }, [setIsActive]);

  const setInactive = useCallback(() => {
    setIsActive(false);
  }, [setIsActive]);

  const documentClickListener = useCallback(() => {
    setInactive();
  }, [setInactive]);

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', documentClickListener);
    } else {
      document.removeEventListener('click', documentClickListener);
    }
  }, [isActive, documentClickListener]);

  return [isActive, setActive, setInactive];
}