import { useMemo } from 'react';
import _ from 'lodash';
import { useSaving } from '../core';

export function useBarChart() {
  const uid = useMemo(() => _.uniqueId('datasailsChart'), []);
  const { saving } = useSaving();
  /**
   * Unminified source:
   */
  // (() => {
  //   const ICON_OPEN_CLASS = 'is-open';
  //   const table = document.getElementById('${uid}');
  //   const rows = Array.prototype.slice.call(table.querySelectorAll('tbody tr[data-id]'));
  //   rows.forEach(row => {
  //     const toggle = row.querySelector('.project-count-label');
  //     if (toggle) {
  //       const id = row.getAttribute('data-id');
  //       const level = Number(row.getAttribute('data-level'));
  //       const children = rows.filter(child => {
  //         const childId = child.getAttribute('data-id');
  //         return childId !== id && childId.substr(0, id.length) === id;
  //       });
  //       toggle.addEventListener('click', () => {
  //         let targets = children.slice();
  //         const icon = toggle.querySelector('svg');
  //         const newVisibility = !targets[0].hidden;
  //         if (!newVisibility) {
  //           icon.classList.add('is-open');
  //           targets = targets.filter(child => Number(child.getAttribute('data-level')) - 1 === level);
  //         } else {
  //           icon.classList.remove('is-open');
  //         }
  //         targets.forEach(child => {
  //           child.hidden = newVisibility;
  //         });
  //       });
  //     }
  //   });
  // })();

  const scriptSrc = `data:application/javascript;base64,${btoa(`"use strict";!function(){var t=document.getElementById("${uid}"),e=Array.prototype.slice.call(t.querySelectorAll("tbody tr[data-id]"));e.forEach(function(t){var r=t.querySelector(".project-count-label");if(r){var i=t.getAttribute("data-id"),a=Number(t.getAttribute("data-level")),n=e.filter(function(t){var e=t.getAttribute("data-id");return e!==i&&e.substr(0,i.length)===i});r.addEventListener("click",function(){var t=n.slice(),e=r.querySelector("svg"),i=!t[0].hidden;i?e.classList.remove("is-open"):(e.classList.add("is-open"),t=t.filter(function(t){return Number(t.getAttribute("data-level"))-1===a})),t.forEach(function(t){t.hidden=i})})}})}();`)}`;

  return {
    uid,
    saving,
    scriptSrc,
  };
}
