import React, { useMemo, useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import "@svgdotjs/svg.panzoom.js";
import _ from 'lodash';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { CHART_PROP_TYPES, HIDDEN_CLASS, GEO_MAX_ZOOM } from "../const";
import {
  geoChartDataBuilder,
  createRefs,
  createRegionElements,
  createCountryElements,
  findViewBox,
  resizeMapElements,
  hideOverlappingElements,
  currencyFormatUnits,
} from "../../util";
import { WorldSvg } from "./WorldSvg";
import { useVisualization } from "../../core/hooks/useVisualization";
import { useSavingGeoChart } from "../../hooks/useSavingGeoChart";
import { IconRemove } from "../../icons";

export function GeoChart({ categories, data, hasInvestment }) {
  // Data
  const { visualization } = useVisualization();
  const [zoomLevel, setZoomLevel] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  // Calculated data
  const hasFilteredRegions = visualization.filterRegions.length > 0;
  const hasOneFilteredRegion = visualization.filterRegions.length === 1;
  const hasMoreThanOneFilteredRegions = visualization.filterRegions.length > 1;
  const hasFilteredCountries = visualization.filterCountries.length > 0;
  // no need to recalculate this ever because the component is recreated when changing from
  // simple map to map with investments, and the dataset won't ever change
  const { maxValue, value, regions, totalInvestment } = useMemo(
    () =>
      geoChartDataBuilder(categories, data, hasInvestment, visualization),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { uid, saving, scriptSrc } = useSavingGeoChart(regions, visualization, value, hasInvestment, maxValue);

  // Refs
  const containerRef = useRef(null);
  const mapRef = useRef(null);
  const countriesGroupRef = useRef(null);
  const regionsGroupRef = useRef(null);
  const bubbleGroupRef = useRef(null);
  const textGroupRef = useRef(null);
  const regionBubbleGroupRef = useRef(null);
  const regionTextGroupRef = useRef(null);
  const viewportsGroupRef = useRef(null);
  const baseZoomLevel = useRef(null);

  // Methods
  const [updateZoomLevel] = useDebouncedCallback((level) => setZoomLevel(level), 200);

  function onClickRegion(regionName) {
    setSelectedRegion(_.find(regions, { name: regionName }));
  }

  function mouseHover(regionName = null) {
    regionsGroupRef.current.find('path').removeClass('is-hover');

    if (regionName) {
      regionsGroupRef.current.find(`path[data-id="${regionName}"]`).addClass('is-hover');
    }
  }

  function viewBoxAndZoom() {
    return findViewBox({
      countriesGroup: countriesGroupRef.current,
      saving,
      selectedRegion,
      value,
      viewportsGroup: viewportsGroupRef.current,
      visualization,
      zoomLevel,
      map: mapRef.current,
    });
  }

  function getCountriesElements() {
    return [textGroupRef, bubbleGroupRef]
      .map(group => group.current
        .find('text, circle')
        .filter(element => !visualization.filterCountries.includes(element.attr('data-id')))
    );
  }

  function hideCountriesData() {
    const countriesGroups = getCountriesElements();
    countriesGroups.map(group => group.addClass(HIDDEN_CLASS));
  }

  function showCountriesData() {
    const countriesGroups = getCountriesElements();
    countriesGroups.map(group => group.removeClass(HIDDEN_CLASS));
  }

  function getRegionsElements() {
    return [regionsGroupRef, regionBubbleGroupRef, regionTextGroupRef].map(group => group.current);
  }

  function hideRegionsData() {
    const regionsGroups = getRegionsElements();
    regionsGroups.map(group => group.addClass(HIDDEN_CLASS));
  }

  function showRegionsData() {
    const regionsGroups = getRegionsElements();
    regionsGroups.map(group => group.removeClass(HIDDEN_CLASS));
  }

  function enablePanZoom() {
    mapRef.current.panZoom({ zoomMax: GEO_MAX_ZOOM, zoomMin: baseZoomLevel.current, oneFingerPan: true });
    mapRef.current.on('zoom', (event) => {
      updateZoomLevel(event.detail.level);
    });
  }

  function disablePanZoom() {
    mapRef.current.panZoom(false);
    mapRef.current.off('zoom');
  }

  function fitMapToBounds(bounds) {
    mapRef.current.viewbox(...bounds);
  }

  // Effects
  /**
   * Creates all groups and sets up refs
   * Also adds zoom event listener to update zoom level
   */
  useEffect(() => {
    const {
      map,
      countriesGroup,
      regionsGroup,
      bubbleGroup,
      textGroup,
      regionBubbleGroup,
      regionTextGroup,
      viewportsGroup,
      unmountFunction,
    } = createRefs(containerRef, updateZoomLevel);

    mapRef.current = map;
    countriesGroupRef.current = countriesGroup;
    regionsGroupRef.current = regionsGroup;
    bubbleGroupRef.current = bubbleGroup;
    textGroupRef.current = textGroup;
    regionBubbleGroupRef.current = regionBubbleGroup;
    regionTextGroupRef.current = regionTextGroup;
    viewportsGroupRef.current = viewportsGroup;

    return unmountFunction;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sets up regions and countries
   */
  useEffect(() => {
    // Activates filtered regions
    regionsGroupRef.current.find("path").forEach(path => {
      path.attr({
        class: classNames({ 'is-active': _.find(visualization.filterRegions, { name: path.attr('data-id') }) }),
      });
    });

    createRegionElements({
      regions,
      regionTextGroup: regionTextGroupRef.current,
      regionsGroup: regionsGroupRef.current,
      regionBubbleGroup: regionBubbleGroupRef.current,
      onClickRegion,
      mouseHover,
    });

    // Activates filtered/active countries
    value.forEach(([country, countryData]) => {
      const path = countriesGroupRef.current.findOne(
        `path[data-id="${countryData.id}"]`
      );
      if (path) {
        path.attr({
          class: "is-active"
        });
      } else {
        console.warn(`Path not found: ${country} / ${countryData.id}`);
      }
    });

    createCountryElements({
      countries: value,
      maxValue,
      countriesGroup: countriesGroupRef.current,
      textGroup: textGroupRef.current,
      bubbleGroup: bubbleGroupRef.current,
      hasInvestment,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Updates map and elements on zoom/region select
   * 1. Fit to bounds (if needed)
   * 2. Resizes all texts and bubbles
   * 3. Enables/disables panZoom
   * 4. Show/hide required elements
   * 4.2. Hides overlapping elements if needed
   */
  useEffect(() => {
    const { bounds, newZoomLevel } = viewBoxAndZoom();

    if (!zoomLevel || saving) {
      fitMapToBounds(bounds);
    }

    if (!baseZoomLevel.current) {
      baseZoomLevel.current = mapRef.current.zoom();
    }

    textGroupRef.current.children().removeClass(HIDDEN_CLASS);
    bubbleGroupRef.current.children().removeClass(HIDDEN_CLASS);

    resizeMapElements({
      textGroup: textGroupRef.current,
      bubbleGroup: bubbleGroupRef.current,
      hasInvestment,
      maxValue,
      zoomLevel: newZoomLevel,
      countriesGroup: countriesGroupRef.current,
      value,
    });

    if ((selectedRegion && !saving) || (hasOneFilteredRegion && !hasFilteredCountries) || (!hasFilteredCountries && !hasFilteredRegions)) {
      enablePanZoom();
      hideRegionsData();
      showCountriesData();
      
      hideOverlappingElements(textGroupRef.current, bubbleGroupRef.current, countriesGroupRef.current);
    } else {
      if (hasMoreThanOneFilteredRegions) {
        disablePanZoom();
        setZoomLevel(null);
      }
      showRegionsData();
      hideCountriesData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel, saving, selectedRegion]);

  return (
    <>
      <div className="geo-chart" id={uid} name="GeoChart">
        <WorldSvg
          ref={containerRef}
          onClickRegion={onClickRegion}
          className={classNames({
            'has-regions': (hasFilteredRegions && hasFilteredCountries) || hasMoreThanOneFilteredRegions,
          })}
        />
        <div className="toolbox columns is-mobile">
          {hasInvestment && hasFilteredRegions && (
            <div className="column is-narrow">
              <div className="investment-box">
                <p className="has-text-weight-bold">
                  {hasMoreThanOneFilteredRegions ? `${selectedRegion?.name || 'World'}` : 'World'}
                </p>
                <p className="is-size-7">
                  Total investment: {currencyFormatUnits(totalInvestment)}
                </p>
                {((hasOneFilteredRegion && !hasFilteredCountries) || (hasMoreThanOneFilteredRegions && selectedRegion)) && (
                  <p className="is-size-7">
                    Regional investment: {selectedRegion?.value?.f || regions[0].value.f}
                  </p>
                )}
              </div>
            </div>
          )}
          <div className="column has-text-right">
            <div className="exit-fullscreen-button-container">
              <button type="button" className={classNames('button is-link is-hidden-desktop m-b-2', { [HIDDEN_CLASS]: !saving })}>
                Close
              </button>
            </div>
            <div className={classNames('back-button-container', { [HIDDEN_CLASS]: !selectedRegion || saving })}>
              <button type="button" className="button is-link" onClick={() => setSelectedRegion(null)}>
                <span className="icon">
                  <IconRemove />
                </span>
                <span>Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className={classNames('fullscreen-trigger', { [HIDDEN_CLASS]: !saving })} />
      </div>
      {saving && (
        <>
          <script src={`data:application/javascript;base64,${btoa(`var _docTemp=window.SVG.Doc;`)}`} type="application/javascript" />
          <script src="https://unpkg.com/@svgdotjs/svg.js@3.0.16/dist/svg.min.js" type="application/javascript" />
          <script src="https://unpkg.com/@svgdotjs/svg.panzoom.js@2.1.1/dist/svg.panzoom.min.js" type="application/javascript" />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/screenfull.js/5.0.2/screenfull.min.js" integrity="sha512-Xsp2RUVnPbF+7nqthvDZO1LGOqTHuR89rYGlbeHM0G76uiv5VwLLRs31tptp5/bzlKod7Zui1O3mjRX5jzYZ4Q==" crossOrigin="anonymous" />
          <script src={scriptSrc} type="application/javascript" />
        </>
      )}
    </>
  )
}

GeoChart.defaultProps = {
  hasInvestment: false,
};

GeoChart.propTypes = {
  ...CHART_PROP_TYPES,
  hasInvestment: propTypes.bool,
};
