/* eslint-disable no-unused-vars */
import _ from "lodash";
import { COUNTRIES_CONTENT_PADDING } from "../../api/countries";

function isTitle(text) {
  return text.attr('data-type') === 'title';
}

function getCountryId(element) {
  return element.attr('data-id');
}

function findElementById(id, group) {
  const selector = `[data-id="${id}"]`;
  return group.findOne(selector);
}

export function resizeMapElements({
  textGroup,
  bubbleGroup,
  zoomLevel,
  maxValue,
  hasInvestment,
  countriesGroup,
  value,
}) {
  const fontElevenSize = `${11 / zoomLevel}px`;
  const fontTwelveSize = `${12 / zoomLevel}px`;

  bubbleGroup.children().forEach(bubble => {
    const id = getCountryId(bubble);
    const countryData = _.find(value, { 1: { id } })[1];
    const diameter = Math.min(Math.max(((countryData.value.v / maxValue) * 20) / zoomLevel, 4 / zoomLevel), 20 / zoomLevel);
    bubble.radius(
      diameter !== Math.Infinity ? diameter : 0
    );
  });

  textGroup.children().forEach(text => {
    const countryId = getCountryId(text);
    const countryPath = findElementById(countryId, countriesGroup);
    const [paddingX, paddingY] = COUNTRIES_CONTENT_PADDING[countryId] || [0, 0];
    const { cx, cy } = countryPath.bbox();

    if (isTitle(text)) {
      if (hasInvestment) {
        const bubble = findElementById(countryId, bubbleGroup);
        if (bubble) {
          const { height: circleHeight, y: circleY } = bubble.bbox();
          text
            .font('size', fontElevenSize)
            .center(cx + paddingX, circleY + (circleHeight / 2) + (16 / zoomLevel) + paddingY);
        }
      } else {
        text.font('size', fontTwelveSize).center(cx + paddingX, cy + paddingY);
      }
    } else {
      const bubble = findElementById(countryId, bubbleGroup);
      const { height: circleHeight, y: circleY } = bubble.bbox();
      text
        .font('size', fontTwelveSize)
        .center(cx + paddingX, circleY + (circleHeight / 2) + paddingY);
    }
  });
}