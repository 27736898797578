import React from "react";
import propTypes from "prop-types";

import "./Spinner.css";

export function Spinner({ overlay, message }) {
  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999999,
    backgroundColor: "white"
  };

  return (
    <div className="spinner-container" style={overlay ? overlayStyle : null}>
      <div className="spinner-center">
        {!!message && <div className="spinner-message">{message}</div>}
        <div className="spinner-fluid">
          <div className="spinner">
            <div />
            <div>
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Spinner.defaultProps = {
  overlay: false,
  message: undefined
};

Spinner.propTypes = {
  overlay: propTypes.bool,
  message: propTypes.string
};
