import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { CHART_PROP_TYPES } from '../const';
import { stackedChartDataBuilder } from '../../util';
import { useSavingChart } from '../../hooks';

export function StackedChart({ categories, data }) {
  const stackedData = useMemo(() => stackedChartDataBuilder(categories, data), [categories, data]);
  const { saving, uid, scriptSrc } = useSavingChart(stackedData);

  return (
    <div className="stacked-chart" name="StackedChart">
      {!saving ? (
        <Chart
          type="area"
          width="100%"
          height="400px"
          series={stackedData.series}
          options={stackedData.options}
        />
      ) : (
        <>
          <div id={uid} />
          <script src={scriptSrc} />
        </>
      )}
    </div>
  );
}

StackedChart.propTypes = CHART_PROP_TYPES;
