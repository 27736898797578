import React from 'react';
import propTypes from 'prop-types';
import { Tag, Delete } from 'bloomer';
import { useDrag } from 'react-dnd';
import { motion } from 'framer-motion';
import { DropArea } from './DropArea';
import { IconRemove } from '../../icons';

export function ColumnHeader({ category, onRemoveCategory, rows }) {
  const [, drag] = useDrag({
    item: { type: category.name },
  });

  return (
    <>
      <DropArea notAccepted={category.name} position="left" rows={rows} />
      <th ref={drag}>
        <Tag tag={motion.div} layout name={category.name}>
          {category.name}
          <Delete isSize="small" onClick={() => onRemoveCategory(category)}>
            <span className="icon">
              <IconRemove />
            </span>
          </Delete>
        </Tag>
      </th>
      <DropArea notAccepted={category.name} position="right" rows={rows} />
    </>
  );
}

ColumnHeader.propTypes = {
  category: propTypes.shape({
    name: propTypes.string.isRequired,
  }).isRequired,
  onRemoveCategory: propTypes.func.isRequired,
  rows: propTypes.number.isRequired,
};
