import _ from 'lodash';
import { parse, compareAsc, format, startOfYear, endOfYear } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '../const';
import { currencyFormat } from './currencyFormat';

const LINE_HEIGHT = 40;
export const BOTTOM_EXTRA_HEIGHT = 74;
const fallbackDate = '1969-12-31';

export function isGapYear(year) {
  return year.toString().startsWith('gap');
}

function getCategory(project, name) {
  return _.find(project.categories, { name }).value;
}

export function scatterChartDataBuilder(categories, data) {
  const rowLabel = categories.find(category => ['number', 'currency'].includes(category.type));
  const barLabel = categories.find(category => ['string', 'country'].includes(category.type));
  const dateLabel = categories.find(category => category.type === 'date');

  let series = data.sort((a, b) => {
    if (rowLabel) {
      const aValue = getCategory(a, rowLabel.name);
      const bValue = getCategory(b, rowLabel.name);

      return bValue - aValue;
    }
    const aValue = getCategory(a, dateLabel.name);
    const bValue = getCategory(b, dateLabel.name);

    return compareAsc(
      parse(aValue || fallbackDate, DEFAULT_DATE_FORMAT, new Date()),
      parse(bValue || fallbackDate, DEFAULT_DATE_FORMAT, new Date()),
    );
  })
  .reduce((group, project) => {
    const row = rowLabel ? getCategory(project, rowLabel.name) : null;
    if ((rowLabel && row) || !rowLabel) {
      const bar = getCategory(project, barLabel.name);
      const date = getCategory(project, dateLabel.name);

      if (date && (Array.isArray(bar) ? bar.length > 0 : bar)) {
        const parsedDate = parse(date, DEFAULT_DATE_FORMAT, new Date()).valueOf();
        const isSplit = project.categories.filter(category => Array.isArray(category.value)).some(category => category.value.length > 1);
        const projectRow = _.find(group, { name: bar, date: parsedDate });

        if (projectRow && rowLabel) {
          projectRow.isSplit = projectRow.isSplit || isSplit;
          projectRow.investment += row || 0;
        } else  if (!projectRow) {
          group.push({
            name: bar,
            date: parsedDate,
            investment: row,
            year: Number(format(parsedDate, 'yyyy')),
            isSplit,
          });
        }
      }
    }

    return group;
  }, []);

  series = _.sortBy(series, rowLabel ? 'investment' : 'date');
  if (rowLabel) {
    series = series.reverse();
  }
  const groupedSeries = _.groupBy(series, 'year');
  const years = _.chain(series)
    .flatMap('year')
    .sort()
    .sortedUniq()
    .value();

  for (let i = 0; i < years.length - 1; i += 1) {
    if (years[i + 1] - years[i] > 1) {
      years.splice(i + 1, 0, _.uniqueId('gap'));
      i += 1;
    }
  }

  const lastYear = years.slice().pop();
  years.push(lastYear + 1);

  const investment = rowLabel && _.chain(series)
    .flatMap('investment')
    .sort((a, b) => b - a)
    .sortedUniq()
    .reduce((group, value) => group.concat([[value, currencyFormat(value)]]), [])
    .value();

  const yearsDotsPositions = [];
  let bottomMostDot = 0;
  const columns = years.map((year, yearIndex) => {
    const isGap = year.toString().startsWith('gap');
    yearsDotsPositions[yearIndex] = [];
    if (!isGap) {
      const parsedYear = parse(year, "yyyy", new Date());
      const start = startOfYear(parsedYear).valueOf();
      const end = endOfYear(parsedYear).valueOf();
      let extraTopOffset = 0;

      return [
        year,
        (groupedSeries[year] || []).map((project, projectIndex) => {
          let top = projectIndex * LINE_HEIGHT;
          const left =
            ((project.date - start) / (end - start)) * 100;

          if (yearIndex > 0) {
            let previousYear = yearsDotsPositions[yearIndex - 1];
            if (isGapYear(years[yearIndex - 1])) {
              previousYear = yearsDotsPositions[yearIndex - 2] || [];
            }
            for (let previousYearProjectIndex = 0; previousYearProjectIndex < previousYear.length; previousYearProjectIndex += 1) {
              const previousYearDotPosition = previousYear[previousYearProjectIndex];
              if (previousYearDotPosition) {
                const [previousTop, previousLeft] = previousYearDotPosition;
                if (previousTop === top + extraTopOffset && previousLeft >= left) {
                  extraTopOffset += 40;
                } else if (previousTop > top + extraTopOffset) {
                  break;
                }
              } else {
                break;
              }
            }
          }
          top += extraTopOffset;
          if (top > bottomMostDot) {
            bottomMostDot = top;
          }
          yearsDotsPositions[yearIndex].push([top, left]);

          return {
            ...project,
            top,
            left
          };
        }),
      ];
    }
    return [year, []];
  });
  const chartHeight = bottomMostDot + LINE_HEIGHT + BOTTOM_EXTRA_HEIGHT;

  return {
    columns,
    chartHeight,
    years,
    investment,
  };
}
