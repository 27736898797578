import React, { useMemo, useState } from 'react';
import { Columns, Column } from 'bloomer';
import classNames from 'classnames';
import { CHART_PROP_TYPES } from '../const';
import { barChartDataBuilder, currencyFormat, addAsterisks } from '../../util';
import { BarAxisIndicators } from './BarAxisIndicators';
import { useVisualization } from '../../core';
import { useBarChart } from '../../hooks';
import { IconDropdown } from '../../icons/components/IconDropdown';

export function BarChart({ categories, data }) {
  const { visualization } = useVisualization();
  const { headers, rows, openRows, total, partiallyIncludedTotal, hasPartiallyIncludedProjects } = useMemo(() => barChartDataBuilder(categories, data, visualization), [categories, data, visualization]);
  const [visibility, setVisibility] = useState(openRows);
  const { uid, saving, scriptSrc } = useBarChart();

  function toggleVisibility(row) {
    setVisibility({
      ...visibility,
      ...row.children.reduce((children, item) => {
        // eslint-disable-next-line no-param-reassign
        children[item.id] = !visibility[item.id];
        if (item.children && !children[item.id]) {
          item.children.forEach(child => {
            // eslint-disable-next-line no-param-reassign
            children[child.id] = false;
          });
        }
        return children;
      }, {}),
    });
  }

  function renderProjectLabel(row) {
    return (
      <>
        {row.children ? (
          <div className="toggle-subitems">
            <span className="icon">
              <IconDropdown className={classNames('dropup', { 'is-open': visibility[row.children[0].id] && !saving })} />
            </span>
          </div>
        ) : null}
        <div className="truncated">
          {addAsterisks(row.children ? `${row.name} (${row.totalChildren})` : row.name, row.isSplit, row.isPartiallyIncluded)}
        </div>
      </>
    );
  }

  function renderRow(row, max, isVisible, level = 1) {
    const isHidden = !isVisible || (saving && level > 1);
    return (
      <tr key={row.id} hidden={isHidden} className={`is-level-${level}`} data-id={row.id} data-level={level}>
        <td className="project-title">
          {row.children ? (
            <>
              {/* eslint-disable-next-line */}
              <div className="project-count-label" onClick={row.children ? () => toggleVisibility(row) : null}>
                {renderProjectLabel(row)}
              </div>
            </>
          ) : renderProjectLabel(row)}
        </td>
        <td className="investment-bar">
          <Columns isGapless isVCentered isMobile>
            <Column isSize="narrow" className="has-text-right">
              {row.formatted}
            </Column>
            <Column>
              <progress max={max} value={row.value} className="progress is-small">{row.value}</progress>
            </Column>
          </Columns>
        </td>
      </tr>
    );
  }

  return (
    <>
      <div className="table-chart" id={uid} name="BarChart">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              {headers.map((header) => <th key={header}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {rows.map(row => [
              renderRow(row, rows[0].value, true),
              row.children?.map(first => [
                renderRow(first, rows[0].value, visibility[first.id], 2),
                first.children ? first.children.map(second => renderRow(second, rows[0].value, visibility[second.id], 3)) : null,
              ]),
            ])}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2">
                <Columns isGapless isVCentered isMobile>
                  <Column className="has-text-right has-text-weight-bold">
                    Total investment:&nbsp;
                    {hasPartiallyIncludedProjects && (
                      <>
                        {currencyFormat(partiallyIncludedTotal, 'USD', 'en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                        &nbsp;&ndash;&nbsp;
                      </>
                    )}
                    {currencyFormat(total, 'USD', 'en-US', {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                      style: 'decimal',
                    })}
                  </Column>
                  <Column isSize="narrow">
                    <BarAxisIndicators maxValue={rows[0].value || 0} />
                  </Column>
                </Columns>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {saving && <script src={scriptSrc} />}
    </>
  );
}

BarChart.propTypes = CHART_PROP_TYPES;
