import { useDispatch, useSelector } from 'react-redux';
import { getTitle, setTitle as setReduxTitle } from '../redux';

export function useTitle() {
  const dispatch = useDispatch();
  const title = useSelector(getTitle);

  function setTitle(newTitle) {
    dispatch(setReduxTitle(newTitle));
  }

  return {
    title,
    setTitle,
  };
}
