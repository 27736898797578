import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { DATA_TYPE, CATEGORIES_TYPE, INVESTMENT_UNDISCLOSED } from '../../const';
import { isEmpty, currencyFormat, dateFormat } from '../../util';

export function DataRow({ project, categories }) {
  return (
    <tr>
      {categories.map(category => {
        const projectCategory = _.find(project.categories, { name: category.name });
        const isNull = !projectCategory || isEmpty(projectCategory.value);
        const celClassName = isNull ? '' : classNames(`cel-${_.kebabCase(category.name)}`, { 'has-text-right': projectCategory.type === 'currency' });

        if (!isNull) {
          let value = '';

          switch (projectCategory.type) {
            case 'currency':
              value = currencyFormat(projectCategory.value, projectCategory.currency);
              break;
            case 'date':
              value = dateFormat(projectCategory.value);
              break;
            case 'country':
            default:
              value = Array.isArray(projectCategory.value)
                ? projectCategory.value.join(', ')
                : projectCategory.value;
          }

          return (
            <td
              key={category.name}
              className={celClassName}
            >
              <div>
                {value}
              </div>
            </td>
          );
        }
        return (
          <td
            key={category.name}
            className={celClassName}
          >
            {INVESTMENT_UNDISCLOSED}
          </td>
        );
      })}
    </tr>
  );
}

DataRow.propTypes = {
  project: DATA_TYPE.isRequired,
  categories: CATEGORIES_TYPE.isRequired,
};
