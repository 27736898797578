import { useDispatch, useSelector } from 'react-redux';
import { getData as getReduxData, setData as setReduxData } from '../redux';
import { getData } from '../../api';

export function useData() {
  const dispatch = useDispatch();
  const data = useSelector(getReduxData);

  function setData(newData) {
    return dispatch(setReduxData(newData));
  }

  async function loadData(visualizationId) {
    const result = await getData(visualizationId);
    return setData(result);
  }

  return {
    data,
    setData,
    loadData,
  };
}