import React from 'react';
import propTypes from 'prop-types';

export function Base({ children, isWide, isSmall, customViewBox, ...props }) {
  let viewBox = customViewBox || '0 0 32 32';

  if (!customViewBox) {
    if (isWide) {
      viewBox = '0 0 48 32';
    } else if (isSmall) {
      viewBox = '0 0 12 12';
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width="1em" height="1em" fill="currentColor" {...props}>
      {children}
    </svg>
  );
}

Base.defaultProps = {
  isWide: false,
  isSmall: false,
  customViewBox: null,
};

Base.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.element,
  ]).isRequired,
  isWide: propTypes.bool,
  isSmall: propTypes.bool,
  customViewBox: propTypes.string,
};
