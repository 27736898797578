import React from 'react';
import { Base } from './Base';

export function IconScatterChart() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M1.6 0H0v32h48v-1.6H1.6V0zm4.8 9.6a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zm36.8 11.2a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0zm-32-4.8a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zm16 9.6a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0zm1.6-12.8a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zm9.6-6.4a1.6 1.6 0 11-3.2 0 1.6 1.6 0 013.2 0z" />
    </Base>
  );
}
