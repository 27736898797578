import _, { cloneDeep, find } from 'lodash';
import { COUNTRIES, COUNTRIES_IDS } from '../../api/countries';
import { REGIONS, REGIONS_BY_NAME } from '../../api/regions';
import { currencyFormatUnits } from '../currencyFormatUnits';

export function geoChartDataBuilder(categories, data, hasInvestment, visualization) {
  const categoriesNames = _.chain(categories)
    .concat(_.filter(data[0].categories, { type: 'country' }))
    .filter(category => ['country', 'currency'].includes(category.type))
    .flatMap('name')
    .uniq()
    .value();

  let regions = visualization.filterRegions.concat(Array.from(data.reduce((set, row) => {
    const rowCountries = row.categories.find(category => category.type === 'country');
    if (rowCountries && rowCountries.value) {
      rowCountries.value.forEach((country) => {
        if (country in REGIONS_BY_NAME) {
          set.add(country);
        }
      });
    }
    return set;
  }, new Set())).map(region => ({ name: region, countries: REGIONS_BY_NAME[region] })));
  if (regions.length > 0) {
    if (regions.some(region => region.isWorld)) {
      regions = Object.values(REGIONS);
    }
    regions = regions.map((region => ({ ...region, value: hasInvestment ? 0 : null, summed: hasInvestment ? new Set() : null })));
  }

  let countries = visualization.filterCountries.slice();
  countries = new Map(
    countries
      .concat(_.flatMap(visualization.filterRegions, 'countries'))
      .map((country => [COUNTRIES[country], { id: country, value: hasInvestment ? 0 : null }]))
  );

  let minValue = 0;
  let maxValue = 0;

  if (hasInvestment) {
    data.forEach((row) => {
      const rowCountries = cloneDeep(row.categories.find(category => category.type === 'country'));
      const rowValue = hasInvestment && row.categories.find(category => category.type === 'currency' && categoriesNames.includes(category.name));

      if (rowCountries && rowCountries.value?.length) {
        rowCountries.value.reduce((rowAllCountries, country) => (
          country in REGIONS_BY_NAME
            ? rowAllCountries.concat(REGIONS_BY_NAME[country].map(countryId => COUNTRIES[countryId]))
            : rowAllCountries.concat([country])
        ), []).forEach(country => {
          if (countries.has(country) || (visualization.filterRegions.length === 0 && visualization.filterCountries.length === 0)) {
            let entry = countries.get(country);
            if (!entry) {
              entry = { id: COUNTRIES_IDS[country], value: hasInvestment ? 0 : null };
            }
            const region = regions.find(r => r.countries.includes(COUNTRIES_IDS[country]));
            const newEntry = {
              ...entry,
              value: (entry?.value || 0) + (rowValue?.value || 0),
            };
            countries.set(country, newEntry);
            if (region && !region.summed.has(row.id)) {
              region.value += rowValue.value;
              region.summed.add(row.id);
            }
          }
        });
      } else if (rowValue) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [country, entry] of countries) {
          countries.set(country, { ...entry, value: entry.value + rowValue.value });
        }
      }
    });

    regions.forEach((region) => {
      // eslint-disable-next-line no-param-reassign
      region.value = {
        v: region.value,
        f: currencyFormatUnits(region.value),
      };
    });

    const values = [...countries.values()];
    maxValue = _.maxBy(values, 'value').value;
    minValue = _.minBy(values, 'value').value;
  } else if (countries.size === 0) {
    data.forEach((row) => {
      const rowCountries = row.categories.find(category => category.type === 'country');

      if (rowCountries && rowCountries.value?.length) {
        rowCountries.value.forEach(country => {
          countries.set(country, { id: COUNTRIES_IDS[country], value: null });
        });
      }
    });
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [country, entry] of countries) {
    countries.set(country, { ...entry, value: hasInvestment ? {
      v: entry.value,
      f: currencyFormatUnits(entry.value),
    } : null });
  }

  let value = Array.from(countries);
  if (hasInvestment) {
    value = _.sortBy(value, '1.value.v');
  }

  const totalInvestment = hasInvestment ? data.reduce((total, row) => total + (find(row.categories, { type: 'currency' })?.value || 0), 0) : 0;

  return {
    minValue,
    maxValue,
    value,
    regions,
    totalInvestment,
  };
}