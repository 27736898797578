import { COUNTRIES } from "./countries";
import { REGIONS } from "./regions";

const getFilterCountries = filter => {
  if (!filter) {
    return [];
  }

  let countries = [];
  const elements = decodeURIComponent(filter).split("&");
  elements.forEach(el => {
    const keyVal = el.split("=");
    if (keyVal[0] === "country" && keyVal[1]) {
      countries = keyVal[1].split(",");
    }
  });

  return countries;
};

const countryInList = (country, countryList) => {
  for (let i = 0; i < countryList.length; i += 1) {
    const countryName = COUNTRIES[countryList[i].toUpperCase()];
    if (countryName.toLowerCase() === country.toLowerCase()) return true;
  }

  return false;
};

const filterOutCountries = (countries, countryList) => {
  if (!countryList || countryList.length === 0) {
    return countries;
  }

  const filteredCountries = countries
    .map(c => (c === "Congo, Democratic Republic of" ? "Congo, D. R." : c))
    .filter(c => countryInList(c, countryList));

  return filteredCountries;
};

const getFilterRegions = filter => {
  if (!filter) {
    return [];
  }

  let regions = [];
  const elements = decodeURIComponent(filter).split("&");
  elements.forEach(el => {
    const keyVal = el.split("=");
    if (keyVal[0] === "region" && keyVal[1]) {
      regions = keyVal[1].split(",");
    }
  });

  return regions.map(r => REGIONS[r]);
};

export const getDataEWS = async (visualization, data) => {
  return data.map((item, index) => {
    const countries = filterOutCountries(
      item.countries,
      visualization.filterCountries
    );

    const regions = Object.keys(REGIONS)
      .map(key => REGIONS[key])
      .filter(r => item.regions.indexOf(r.name) > -1)
      .map(r => r.name)
      .filter(
        regionName =>
          visualization.filterRegions.length === 0 ||
          visualization.filterRegions.map(fr => fr.name).indexOf(regionName) >
          -1
      );

    return {
      id: index,
      isMultinational: countries.length + regions.length > 1,
      categories: [
        { name: "Project Name", type: "string", value: item.projectName },
        {
          name: "Investment",
          type: "currency",
          value: item.investmentAmount ? item.investmentAmount * 1000000 : null,
          currency: "USD"
        },
        {
          name: "Location",
          type: "country",
          value: [...countries, ...regions]
        },
        {
          name: "Sector",
          type: "string",
          value: item.sectors
        },
        {
          name: "Date of Disclosure",
          type: "date",
          value: item.dateDisclosed
        },
        {
          name: "Financial Institution",
          type: "string",
          value: item.banks
        }
      ]
    };
  });
};

export const getVisualizationEWS = async (visualization, data, visualizationSource) => {
  const filterCountries = getFilterCountries(visualization.filter);

  const filterRegions = getFilterRegions(visualization.filter);

  const visualizationModel = {
    id: visualization.id,
    name: visualization.name,
    filter: visualization.filter,
    filterCountries,
    filterRegions,
    visualizationSettings: visualization.visualization_settings,
    visualizationHtml: visualization.visualization_html,
    dateCreated: visualization.date_created,
    dateModified: visualization.date_modified,
    dateDataUpdated: visualization.date_data_updated,
    source: `${visualizationSource}?${visualization.filter}`,
    autoRebuild: visualization.auto_rebuild
  }

  return {
    visualization: visualizationModel,
    data: await getDataEWS(visualizationModel, data,)
  };
};
