import React from 'react';
import { Base } from './Base';

export function IconStackedChart() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M0 0h1.6v30.4H48V32H0V0zm5.6 26.4c2.4 0 4.5-3.8 7-8 3.1-5.6 6.8-12 12.2-12s9 6.4 12.2 12c2.4 4.3 4.6 8 7 8h.8v.8h-40v-.8h.8zm3.5-.5c2.4-.6 4.7-1.8 6.9-2.9 3-1.5 5.9-3 8.8-3 2.8 0 5.2 1.3 7.8 2.8 2.5 1.4 5.2 2.8 8.7 3.4-2.3-1.5-4.5-3.9-6.8-6.3-3.4-3.6-6.7-7.1-9.7-7.1-3 0-6 3.4-9.2 6.9a39 39 0 01-6.5 6.2z" />
    </Base>
  );
}
