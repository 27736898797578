import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  DropdownMenu,
  DropdownItem,
} from 'bloomer';
import _ from 'lodash';
import classNames from 'classnames';
import { useDropdown } from '../hooks';
import { useCategories, useSelectedCharts, useData } from '../core';
import { CHART_TYPES, MAP_CHART_TYPE } from '../core/const';
import { DataRow, ColumnHeader } from './components';
import { IconDropdown } from '../icons';

export function DataSelector() {
  const [isActive, setActive] = useDropdown();
  const { categories, addCategory, removeCategory } = useCategories();
  const { selectedCharts, setSelectedCharts } = useSelectedCharts();
  const { data } = useData();
  const categoriesOptions = useMemo(
    () => _.chain(data)
      .flatMap('categories')
      .map(category => ({ name: category.name, type: category.type, isArray: Array.isArray(category.value) }))
      .uniqBy('name')
      .differenceBy(categories, 'name')
      .value(),
    [data, categories]
  );
  const hasOptions = categoriesOptions && categoriesOptions.length > 0;
  const boxClassName = classNames('data-selector', {
    'has-no-categories': categories.length < 1,
  });
  const hasMapChart = !!selectedCharts.find(chart => chart.label === MAP_CHART_TYPE.label);

  function addChart(chart) {
    let currentCharts = selectedCharts;
    if (chart.label.includes('Map')) {
      currentCharts = currentCharts.filter((c) => !c.label.includes('Map'));
    }
    setSelectedCharts([
      ...currentCharts,
      { ...chart, isHalf: false }
    ]);
  }

  function removeChart(removed) {
    setSelectedCharts(selectedCharts.filter(chart => chart.label !== removed.label));
  }

  return (
    <Box className={boxClassName}>
      <div className="table-wrapper">
        <table className="table is-striped">
          <tbody>
            <tr>
              {categories.map(category => (
                <ColumnHeader
                  key={category.name}
                  category={category}
                  onRemoveCategory={removeCategory}
                  rows={data.length}
                />
              ))}
              {categories.length < 5 && (
                <th>
                  <Dropdown isActive={isActive} isAlign={categories.length < 1 ? '' : 'right'}>
                    <DropdownTrigger onClick={setActive}>
                      <Button isColor="grey-dark" isOutlined>
                        <span>+ Add Category</span>
                        <span className="icon">
                          <IconDropdown />
                        </span>
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownContent>
                        {hasOptions ? categoriesOptions.map(category => (
                          <DropdownItem tag="a" key={category.name} onClick={() => addCategory(category)}>
                            {category.name}
                          </DropdownItem>
                        )) : (
                          <DropdownItem>
                            No category available
                          </DropdownItem>
                        )}
                      </DropdownContent>
                    </DropdownMenu>
                  </Dropdown>
                </th>
              )}
            </tr>
            {data.map((project) => <DataRow project={project} categories={categories} key={project.id} />)}
          </tbody>
        </table>
      </div>
      <div className="buttons">
        <Button
          isColor="info"
          isOutlined
          isActive={hasMapChart}
          onClick={() => hasMapChart ? removeChart(MAP_CHART_TYPE) : addChart(MAP_CHART_TYPE)}
        >
          <span className="icon">
            <MAP_CHART_TYPE.icon />
          </span>
          <span>
            {`${hasMapChart ? 'Remove' : 'Add'} ${MAP_CHART_TYPE.label}`}
          </span>
        </Button>
        {CHART_TYPES.filter(type => type.enabled(categories, data)).map(chartType => {
          const isSelected = !!selectedCharts.find(chart => chart.label === chartType.label);

          return (
            <Button
              key={chartType.label}
              isColor="info"
              isOutlined
              isActive={isSelected}
              onClick={() => isSelected ? removeChart(chartType) : addChart(chartType)}
            >
              <span className="icon">
                <chartType.icon />
              </span>
              <span>
                {`${isSelected ? 'Remove' : 'Add'} ${chartType.label}`}
              </span>
            </Button>
          );
        })}
      </div>
    </Box>
  );
}
