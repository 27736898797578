import React from 'react';
import { Base } from './Base';

export function IconCopyright() {
  return (
    <Base>
      <path fillRule="evenodd" d="M16 0a16 16 0 000 32 16 16 0 000-32zm0 28.9a12.9 12.9 0 110-25.8 12.9 12.9 0 010 25.8zm6.9-6.5l.2-.5-.1-.5-1.3-1.7c-.3-.4-.8-.5-1.2-.2-.6.6-2.1 1.8-4.2 1.8-3.3 0-5-2.7-5-5.4 0-2.9 1.9-5.2 5-5.2 1.9 0 3.4 1 4 1.5.3.3.9.2 1-.2l1.3-1.8.1-.5-.2-.5A9 9 0 0016 7c-5 0-9 3.8-9 9 0 5.1 3.7 9.1 9 9.1 3.8 0 6.2-2 6.8-2.7z" />
    </Base>
  );
}
