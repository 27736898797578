import React from 'react';
import { Base } from './Base';

export function IconEdit() {
  return (
    <Base>
      <path fillRule="evenodd" d="M23.2 12.4L21.7 14h-.3-.2l-3.5-3.5-.1-.2.1-.3 1.5-1.5c.3-.3.6-.4 1-.4s.8.1 1 .4l2 2c.3.2.4.6.4 1s-.1.7-.4 1zm-6.3-1c0-.2.2-.2.3-.2l.2.1 3.5 3.5.1.2-.1.3-8.2 8.2-3.8.7c-.3 0-.5 0-.7-.2s-.2-.4-.2-.7l.7-3.8 8.2-8.2zm-4 7h.6l4.9-4.8v-.4-.3l-.4-.1-.3.1-4.8 4.8-.1.3.1.4zm-1.8.8H10l-.4 2 1 1 2-.4v-1.1h-1.5v-1.5z" />
    </Base>
  );
}
