import { SVG } from "@svgdotjs/svg.js";
import { COUNTRIES_CONTENT_PADDING } from "../../api/countries";

/* eslint-disable no-unused-vars */
export function createCountryElements({
  countries,
  maxValue,
  countriesGroup,
  textGroup,
  bubbleGroup,
  hasInvestment,
}) {
  countries.forEach(([country, countryData]) => {
    const path = countriesGroup.findOne(
      `path[data-id="${countryData.id}"]`
    );
    if (path) {
      const hasNoInvestment = (hasInvestment && (countryData.value === null || countryData.value.v === 0));
      const { cx, cy } = path.bbox();

      if (hasNoInvestment) {
        path.addClass('has-no-investment');
      } else {
        const [paddingX, paddingY] = COUNTRIES_CONTENT_PADDING[countryData.id] || [0, 0];

        const text = SVG().text(country);
        text
          .font({
            family: 'Lato',
            size: '12px',
            anchor: 'middle',
            leading: '1em',
            weight: 700,
          })
          .attr('data-id', countryData.id)
          .attr('data-type', 'title')
          .attr('vector-effect', 'non-scaling-size')
          .addTo(textGroup)
          .center(cx + paddingX, cy + paddingY - 12);
        if (countryData.value !== null && countryData.value.v > 0 && maxValue > 0) {
          const diameter = Math.min(Math.max(((countryData.value.v / maxValue) * 20), 4), 20);

          const circle = SVG().circle(
            diameter !== Math.Infinity ? diameter : 0
          );
          circle
            .attr('data-id', countryData.id)
            .addTo(bubbleGroup)
            .center(cx + paddingX, cy + paddingY);
          
          const { y: circleY, height: circleHeight } = circle.bbox();
          text
            .center(cx + paddingX, circleY + (circleHeight / 2) + paddingY)
            .attr('data-type', 'value')
            .text(countryData.value.f);

          const countryName = SVG().text(country);
          countryName
            .font({
              family: 'Lato',
              size: '11px',
              anchor: 'middle',
              leading: '1em',
              weight: 700,
            })
            .attr('data-id', countryData.id)
            .attr('data-type', 'title')
            .attr('vector-effect', 'non-scaling-size')
            .addTo(textGroup)
            .center(cx + paddingX, circleY + (circleHeight / 2) + 6 + paddingY)
        }
      }
    } else {
      console.warn(`Path not found: ${country} / ${countryData.id}`);
    }
  });
}