import { useMemo } from 'react';
import _ from 'lodash';
import { createDrawFunction } from '../util';
import { useSaving } from '../core';

export function useSavingChart(options) {
  const uid = useMemo(() => _.uniqueId('datasailsChart'), []);
  const scriptSrc = useMemo(() => createDrawFunction({
    uid,
    options,
  }), [uid, options]);
  const { saving } = useSaving();

  return {
    uid,
    scriptSrc,
    saving,
  };
}
