/**
 * Formats currency
 *
 * @param {number} value Value to format
 * @param {string} currency Valid currency code
 * @param {string} locale Valid ISO country code
 * @param {Intl.NumberFormatOptions} options Other, optional options
 * @see https://stackoverflow.com/a/40724354
 */
export function currencyFormat(value, currency = 'USD', locale = 'en-US', options = {}) {
  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    useGrouping: true,

    ...options,
  });

  if (value > 1000000) {
    return `${formatter.format(value / 1000000)} M`;
  } else if (value > 1000) {
    return `${formatter.format(value / 1000)} K`;
  }

  return `${formatter.format(value)}`;
}

export const currencyFormatConstruct = [
  'n',
  `var r=arguments.length>1&&void 0!==arguments[1]?arguments[1]:"USD",e=arguments.length>2&&void 0!==arguments[2]?arguments[2]:"en-US",t=arguments.length>3&&void 0!==arguments[3]?arguments[3]:{},c=Intl.NumberFormat(e,Object.assign({style:"currency",currency:r,useGrouping:!0},t));return"".concat(c.format(n/1e6)," M")`,
];
