import React, { useState, useMemo } from 'react';
import { Columns, Column, Button, Field, Control, Input, Subtitle } from 'bloomer';
import _ from 'lodash';
import { format, parse } from 'date-fns';
import propTypes from 'prop-types';
import { useSaving } from '../../core';
import { DATA_TYPE, DEFAULT_DATE_FORMAT } from '../../const';
import { TITLE_DATE_FORMAT } from '../const';
import { useTitle } from '../../core/hooks';
import { IconPlus } from '../../icons/components/IconPlus';
import { IconDone } from '../../icons/components/IconDone';
import { IconEdit } from '../../icons/components/IconEdit';

export function VisualizationTitle({ data }) {
  const { title, setTitle } = useTitle();
  const [titleVisible, setTitleVisible] = useState(!!title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const { saving } = useSaving();
  const interval = useMemo(() => {
    if (data) {
      const dates = _.chain(data)
        .map(row => _.find(row.categories, { type: 'date' }))
        .flatMap('value')
        .map(date => date ? Number(parse(date, DEFAULT_DATE_FORMAT, new Date())) : null)
        .filter(Boolean)
        .sort()
        .value();
      const lowest = dates.shift();
      const highest = dates.pop() || lowest;

      if (lowest && highest) {
        return `${format(lowest, TITLE_DATE_FORMAT)} - ${format(highest, TITLE_DATE_FORMAT)}`;
      }
    }
    return '';
  }, [data]);

  function onAddTitle() {
    setTitleVisible(true);
    setIsEditingTitle(true);
  }

  function onEditTitle() {
    setIsEditingTitle(true);
  }

  function onChangeTitle(event) {
    setTitle(event.currentTarget.value);
  }

  function onHideTitle() {
    setTitleVisible(false);
  }

  function onFinishEditTitle() {
    setIsEditingTitle(false);
    if (!title) {
      onHideTitle();
    }
  }

  return (
    <div className="visualization-title">
      {!titleVisible ? (
        <Columns isVCentered isMobile>
          <Column isSize="narrow">
            <Subtitle tag="p">
              {interval}
            </Subtitle>
          </Column>
          {!saving && (
            <Column>
              <Button onClick={onAddTitle} isColor="info">
                <span className="icon is-medium">
                  <IconPlus />
                </span>
                <span>Add Title</span>
              </Button>
            </Column>
          )}
        </Columns>
      ) : (
        <Columns isMobile>
          {isEditingTitle && !saving ? (
            <>
              <Column>
                <Field>
                  <Control>
                    <Input
                      defaultValue={title}
                      onChange={onChangeTitle}
                    />
                  </Control>
                </Field>
                <Subtitle tag="p" isSize="6">
                  {interval}
                </Subtitle>
              </Column>
              <Column isSize="narrow">
                <Button
                  isOutlined
                  isColor="info"
                  onClick={onFinishEditTitle}
                >
                  <span className="icon is-medium">
                    <IconDone />
                  </span>
                  <span>Done</span>
                </Button>
              </Column>
            </>
          ) : (
            <>
              <Column isSize="narrow">
                <Subtitle tag="h3">
                  {title}
                </Subtitle>
                <Subtitle tag="p" isSize="6">
                  {interval}
                </Subtitle>
              </Column>
              {!saving && (
                <Column isSize="narrow">
                  <div className="buttons">
                    <Button
                      isOutlined
                      isColor="info"
                      onClick={onEditTitle}
                    >
                      <span className="icon is-medium">
                        <IconEdit />
                      </span>
                      <span>Edit</span>
                    </Button>
                    <Button
                      isOutlined
                      isColor="info"
                      onClick={onHideTitle}
                    >
                      Hide Title
                    </Button>
                  </div>
                </Column>
              )}
            </>
          )}
        </Columns>
      )}
    </div>
  );
}

VisualizationTitle.propTypes = {
  data: propTypes.arrayOf(DATA_TYPE).isRequired,
};
