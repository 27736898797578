import { useSelector } from "react-redux";
import { getSettings } from "../redux";

export function useVisualizationSettings() {
  const settings = useSelector(getSettings);

  return {
    settings
  };
}
