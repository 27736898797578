import { getDataDEMO1, getVisualizationDEMO1 } from "./demo1Api";
import { getDataFORK, getVisualizationFORK } from "./forkApi";
import { getDataEWS, getVisualizationEWS } from "./ewsApi";

export const getData = async (visualization, data, configuration) => {
  if (configuration === "EWS") {
    return getDataEWS(visualization, data);
  }
  if (configuration === "DEMO1") {
    return getDataDEMO1(visualization, data);
  }
  if (configuration === "FORK") {
    return getDataFORK(visualization, data);
  }

  throw new Error(`Unknown configuration ${configuration}`);
};

export const getVisualization = async visualizationId => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/visualizations/${visualizationId}?apiKey=${process.env.REACT_APP_API_KEY}`
  );

  const { visualization, data, configuration, visualizationSource } = await res.json();

  if (configuration === "EWS") {
    return getVisualizationEWS(visualization, data, visualizationSource);
  }
  if (configuration === "DEMO1") {
    return getVisualizationDEMO1(visualization, data, visualizationSource);
  }
  if (configuration === "FORK") {
    return getVisualizationFORK(visualization, data, visualizationSource);
  }

  throw new Error(`Unknown configuration ${configuration}`);
};

export const saveVisualization = async visualization => {
  const data = {
    id: visualization.id,
    name: visualization.name,
    filter: visualization.filter,
    visualization_settings: visualization.visualizationSettings,
    visualization_html: visualization.visualizationHtml,
    date_created: visualization.dateCreated,
    date_modified: visualization.dateModified,
    date_data_updated: visualization.dateDataUpdated,
    auto_rebuild: visualization.autoRebuild
  };

  const saveDS = fetch(
    `${process.env.REACT_APP_API_URL}/visualizations/${visualization.id}?apiKey=${process.env.REACT_APP_API_KEY}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        visualization: {
          name: visualization.name,
          visualizationHtml: visualization.visualizationHtml,
          autoRebuild: visualization.autoRebuild
        },
        data: JSON.stringify(data)
      })
    }
  );

  await Promise.all([saveDS]);
};
