import React from 'react';
import { Base } from './Base';

export function IconMapChart() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M21.1 7.1c0-4 3-7.1 6.9-7.1s6.8 3.2 6.8 7.1c0 3.2-4.5 9-6.2 11.1-.3.4-1 .4-1.3 0-1.7-2.1-6.2-7.9-6.2-11zm16 0c0 2-1.7 5.1-3.5 8V32l12.6-3.7c1-.3 1.8-1 1.8-1.9V3.8c0-.7-1-1.2-2-1l-9 3v1.3zm-5.3 10.6l.2-.4V32l-16-4.2v-25l3.6.9c-.4 1-.6 2.2-.6 3.4 0 4 5.9 11.5 8.1 14.2.5.6 1.3.6 1.8 0l2.9-3.6zM0 31V8.4c0-.9.7-1.7 1.8-2l12.6-3.6v25L2 32c-1 .3-2-.2-2-1z" />
    </Base>
  );
}
