import React from 'react';
import { Base } from './Base';

export function IconDone() {
  return (
    <Base>
      <path fillRule="evenodd" d="M22.1 9.7l.3-.1.4.1 1 1.1c.2.1.2.2.2.3l-.2.4L12.3 23l-.3.1h-.3l-5.1-5.2-.2-.3c0-.1 0-.3.2-.4l1-1c.1-.2.2-.2.4-.2l.3.1L12 20 22.1 9.7z" />
    </Base>
  );
}
