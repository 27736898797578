export function getSelectedCharts(state) {
  return state.selectedCharts;
}

export function getCategories(state) {
  return state.categories;
}

export function getData(state) {
  return state.data;
}

export function getSaving(state) {
  return state.saving;
}

export function getTitle(state) {
  return state.title;
}

export function getVisualization(state) {
  return state.visualization;
}

export function getSettings(state) {
  return {
    selectedCharts: getSelectedCharts(state),
    categories: getCategories(state)
  };
}
