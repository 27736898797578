import _ from 'lodash';
import { currencyFormat } from './currencyFormat';
import { COUNTRIES_IDS, COUNTRIES } from '../api/countries';
import { REGIONS_BY_NAME } from '../api/regions';
import { INVESTMENT_UNDISCLOSED } from '../const';

/**
 * @typedef {{ name: String, type: String, isArray: Boolean }} Category
 */
/**
 * @typedef {{ filterCountries: String[], filterRegions: String[] }} Visualization
 */
/**
 * @typedef {{ name: string, type: string, value: String | String[] | Number }} DataCategory
 */
/**
 * @typedef {{ id: Number, categories: DataCategory[], isMultinational: Boolean }} Data
 */
/**
 * @typedef {Object} ChartData
 * @property {String} name
 * @property {Number} value
 * @property {String} formatted
 * @property {String} id
 * @property {ChartData[]} [children]
 * @property {Number} [totalChildren]
 * @property {Boolean} [isPartiallyIncluded]
 * @property {Boolean} [isSplit]
 */

/**
 * @param {ChartData[]} array 
 * @returns {Number}
 */
function countChildren(array) {
  return array.reduce((total, child) => {
    if (!child.children) {
      return total + 1;
    }
    return total + countChildren(child.children);
  }, 0);
}

/* eslint-disable no-param-reassign */
/**
 * @param {String} name
 * @param {ChartData[]} children
 */
function updateIds(name, children) {
  return children.map(child => {
    const clone = _.clone(child);
    clone.id = `${name}::${child.id}`;
    if (clone.children) {
      clone.children = updateIds(name, child.children);
    }
    return clone;
  });
}
/* eslint-enable */

/**
 * @param {String} name 
 * @param {Boolean} [hasChildren] 
 * @param {Number} [value] 
 * @param {ChartData[]} [children] 
 * @returns {ChartData}
 */
const itemObject = (name, hasChildren = true, value = 0, children = [], isPartiallyIncluded = false) => hasChildren ? ({
  name,
  children: updateIds(name, children),
  value: value || 0,
  formatted: !value ? INVESTMENT_UNDISCLOSED : currencyFormat(value),
  totalChildren: countChildren(children),
  id: name,
  isPartiallyIncluded,
  isSplit: false,
}) : ({
  name,
  value: value || 0,
  formatted: !value ? INVESTMENT_UNDISCLOSED : currencyFormat(value),
  id: name,
  isPartiallyIncluded,
  isSplit: false,
});

/** @param {ChartData[]} array */
function getRowsIds(array) {
  return array.reduce((rows, data) => ({
    ...rows,
    [data.id]: false,
    ...(data.children ? getRowsIds(data.children) : {}),
  }), /** @type {{ [key: string]: Boolean }} */({}));
}

/**
 * @param {ChartData[]} dataset 
 * @returns {ChartData[]}
 */
function mergeByName(dataset) {
  const groups = _.groupBy(dataset, 'name');
  return _.chain(groups)
    .reduce((result, group) => (
      result.concat(group.reduce((reduced, current) => {
        const total = reduced.value + current.value;
        const isPartiallyIncluded = reduced.isPartiallyIncluded || current.isPartiallyIncluded;
        const isSplit = reduced.isSplit || current.isSplit;
        const formatted = !total ? INVESTMENT_UNDISCLOSED : currencyFormat(total);
        if (current.children) {
          const children = mergeByName((reduced.children || []).concat(current.children));
          return {
            ...reduced,
            isPartiallyIncluded,
            isSplit,
            value: total,
            formatted,
            children,
            totalChildren: reduced.totalChildren + current.totalChildren,
          };
        }
        return {
          ...reduced,
          isPartiallyIncluded,
          isSplit,
          value: total,
          formatted,
        };
      }))
    ), /** @type {ChartData[]} */([]))
    .sortBy('value')
    .reverse()
    .value();
}

/**
 * @param {ChartData} dataset 
 * @returns {Boolean}
 */
function hasSplitChildren(dataset) {
  return dataset.children?.some(d => d.isSplit || d.children?.some(c => hasSplitChildren(c)) || false) || false;
}

/**
 * @param {ChartData} dataset 
 * @returns {Boolean}
 */
function hasPartiallyIncludedChildren(dataset) {
  return dataset.children?.some(d => d.isPartiallyIncluded || d.children?.some(c => hasSplitChildren(c)) || false) || false;
}

/**
 * @param {ChartData[]} dataset
 * @param {Boolean} isSplit
 * @returns {ChartData[]}
 */
function markSplitAndPartiallyIncluded(dataset, isSplit, isPartiallyIncluded) {
  return dataset.map(d => {
    /* eslint-disable no-param-reassign */
    if ((isSplit || isPartiallyIncluded) && d.children) {
      d.children = markSplitAndPartiallyIncluded(d.children, isSplit, isPartiallyIncluded);
    }
    d.isPartiallyIncluded = hasPartiallyIncludedChildren(d) || d.isPartiallyIncluded || isPartiallyIncluded;
    d.isSplit = hasSplitChildren(d) || d.isSplit || isSplit;
    /* eslint-enable */
    return d;
  });
}

/**
 * @param {Category[]} categories 
 * @param {Data[]} data 
 * @param {Visualization} visualization 
 */
export function barChartDataBuilder(categories, data, visualization) {
  const filteredCountries = new Set(visualization.filterCountries.concat(_.chain(visualization.filterRegions).flatMap('countries').flatten().value()));
  const numberCategory = categories.find(c => ['number', 'currency'].includes(c.type));
  const aggregators = categories.filter(c => ['string', 'country'].includes(c.type)).slice(0, 3).sort((a, b) => {
    if (!a.isArray) {
      return 1;
    }
    if (!b.isArray) {
      return -1;
    }
    return 0;
  });
  const headers = [
    aggregators.length > 1 ? aggregators.filter(c => c.isArray).map(c => c.name).join(' / ') : aggregators[0].name,
    numberCategory.name,
  ];
  let partiallyIncludedTotal = 0;
  /** @type {Set<Number>} */
  const partiallySummedProjects = new Set();
  let total = 0;
  let hasPartiallyIncludedProjects = false;

  let rows = data
    .reduce((result, row) => {
      const isIncluded = aggregators.every((aggregator) => {
        const aggregatorValue = row.categories.find(c => c.name === aggregator.name);

        if (aggregatorValue.type === 'country') {
          return (aggregatorValue.value && /** @type {String[]} */(aggregatorValue.value).length > 0) ||
            filteredCountries.size === 0 ||
            /** @type {String[]} */(aggregatorValue.value).some(country => filteredCountries.has(COUNTRIES_IDS[country]));
        }
        if (Array.isArray(aggregatorValue.value)) {
          return aggregatorValue.value.length > 0;
        }
        return !!aggregatorValue.value;
      });

      if (isIncluded) {
        const rowValue = /** @type {Number} */(row.categories.find(c => c.name === numberCategory.name).value);
        total += rowValue || 0;

        const rowData = aggregators.reduceRight((dataset, aggregator) => {
          const aggregatorData = _.cloneDeep(row.categories.find(c => c.name === aggregator.name));
          const isLocationAggregator = aggregatorData.type === 'country';

          if (isLocationAggregator) {
            aggregatorData.value = aggregatorData.value.reduce((allCountries, country) => {
              if (country in REGIONS_BY_NAME) {
                return allCountries.concat(REGIONS_BY_NAME[country].map(id => COUNTRIES[id]));
              }
              return allCountries.concat([country]);
            }, /** @type {String[]} */([]));
          }

          const value = isLocationAggregator
            ? _.uniq(/** @type {String[]} */(aggregatorData.value)
              .filter(country => filteredCountries.size === 0 || filteredCountries.has(COUNTRIES_IDS[country])))
            : aggregatorData.value;
          
          const isPartiallyIncluded = isLocationAggregator && value.length < aggregatorData.value.length;
          hasPartiallyIncludedProjects = hasPartiallyIncludedProjects || isPartiallyIncluded;
          
          if (Array.isArray(value)) {
            const isSplit = value.length > 1;
            const children = dataset.length > 0 ? _.cloneDeep(dataset) : [];
            /**
             * @type {ChartData[]}
             */
            let aggregatorResult;

            const { id } = row;
            const projectAlreadySummed = partiallySummedProjects.has(id);
            if (isLocationAggregator && !isPartiallyIncluded && !projectAlreadySummed) {
              partiallySummedProjects.add(id);
              partiallyIncludedTotal += rowValue || 0;
            }

            if (!dataset.length) {
              aggregatorResult = value.map(v => itemObject(v, false, rowValue, children, isPartiallyIncluded));
            } else {
              aggregatorResult = value.map(v => itemObject(v, true, rowValue, children));
            }

            return markSplitAndPartiallyIncluded(aggregatorResult, isSplit, isPartiallyIncluded || hasPartiallyIncludedChildren(children));
          }

          const rowItemObject = itemObject(/** @type {String} */(value), false, rowValue, [], isPartiallyIncluded);
          const newDataset = dataset.concat([rowItemObject]);
          return newDataset;
        }, /** @type {ChartData[]} */([]));

        return result.concat(rowData);
      }

      return result;
    }, /** @type {ChartData[]} */([]));

  rows = mergeByName(rows);

  const openRows = getRowsIds(rows);

  const result = {
    headers,
    rows,
    openRows,
    total,
    partiallyIncludedTotal,
    hasPartiallyIncludedProjects,
  };

  return result;
}
