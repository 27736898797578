import React from 'react';
import { Base } from './Base';

export function IconTableChart() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M38 5V0h-9v5h9zm0 1h-9v26h9V6zm1 26V6h9v23c0 2-1 3-3 3h-6zm0-27V0h6c2 0 3 1 3 3v2h-9zM28 0v5h-8V0h8zm-8 6h8v26h-8V6zm-1-1V0h-9v5h9zm0 1h-9v26h9V6zM9 0v5H0V3c0-2 1-3 3-3h6zM0 6h9v26H3c-2 0-3-1-3-3V6z" />
    </Base>
  );
}
