const MAP_VB_MARGIN = 20;
const MAP_TOTAL_X = 1176;

export function findViewBox({
  selectedRegion,
  saving,
  visualization,
  viewportsGroup,
  countriesGroup,
  value,
  zoomLevel,
}) {
  let minY = Infinity;
  let minX = Infinity;
  let maxY = 0;
  let maxX = 0;
  let countries = [];

  if (!selectedRegion || saving) {
    if (visualization.filterRegions.length > 0 && visualization.filterCountries.length < 1) {
      countries = visualization.filterRegions.map(region => viewportsGroup.findOne(`[data-id="${region.name}"]`));
    } else {
      countries = value
        .map(([, countryData]) => countriesGroup.findOne(`[data-id="${countryData.id}"]`))
        .filter(Boolean);
    }
  } else {
    countries = [viewportsGroup.findOne(`[data-id="${selectedRegion.name}"]`)];
  }

  countries.forEach(path => {
    const { x, y, x2, y2 } = path.bbox();

    if (x < minX) {
      minX = x;
    }
    if (y < minY) {
      minY = y;
    }
    if (x2 > maxX) {
      maxX = x2;
    }
    if (y2 > maxY) {
      maxY = y2;
    }
  });

  const newZoomLevel = (zoomLevel && !saving) ? (zoomLevel / 1.5) : Math.min(
    MAP_TOTAL_X / (maxX - minX) / 5 * 2,
    5
  );

  return {
    bounds: [
      minX - MAP_VB_MARGIN,
      minY - MAP_VB_MARGIN,
      maxX - minX + MAP_VB_MARGIN,
      maxY - minY + MAP_VB_MARGIN + 20,
    ],
    newZoomLevel,
  };
}