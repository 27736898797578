import React from 'react';
import { Base } from './Base';

export function IconPlus() {
  return (
    <Base>
      <path fillRule="evenodd" d="M24 14.6c.2 0 .4.3.4.5v2c0 .2-.2.4-.4.4h-6.6V24c0 .2-.2.4-.4.4h-2a.5.5 0 01-.4-.5v-6.5H8c-.4 0-.5-.1-.5-.5V15c0-.2.2-.4.5-.4h6.5V8l.1-.4.4-.1h1.8c.4 0 .5.1.5.5v6.5H24z" />
    </Base>
  );
}
