export const TITLE_DATE_FORMAT = 'dd MMM yyyy';

export const DROP_AREAS = [
  'top',
  'right',
  'bottom',
  'left'
];

export const HALF_DROP_AREAS = [
  'right',
  'left'
];

export const ABOVE_DROP_AREAS = [
  'top',
  'left'
];

export const VERTICAL_DROP_AREAS = [
  'above',
  'below'
];
