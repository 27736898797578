import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import _ from 'lodash';
import { CHART_PROP_TYPES } from '../../charts/const';
import { DROP_AREAS } from '../const';
import { DropArea } from './DropArea';
import { useSaving, useSelectedCharts } from '../../core';

export function ChartContainer({ data, categories, chart }) {
  const { saving } = useSaving();
  const { selectedCharts } = useSelectedCharts();
  const [{ isDragging }, drag] = useDrag({
    item: { type: chart.label, chart },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const chartIndex = selectedCharts.indexOf(chart);
  const otherHalfIndex = _.findIndex(selectedCharts, { label: chart.isHalf });
  const isHalf = !!chart.isHalf;
  const isFirstOfCouple = isHalf && chartIndex < otherHalfIndex;
  const containerClassNames = classNames('column chart-container', {
    'is-half': isHalf,
    'is-full': !chart.isHalf,
    'is-dragging': isDragging,
    'is-first-of-couple': isFirstOfCouple,
    'is-geo-chart': chart.label.includes('Map'),
    'is-table-chart': ['Bars', 'Table'].includes(chart.label),
  });

  return (
    <>
      {isHalf && isFirstOfCouple && <DropArea position="above" chart={chart} />}
      <div className={containerClassNames} ref={drag}>
        {!saving && DROP_AREAS.map(position => (
          <DropArea position={position} notAccepted={chart.label} key={position} />
        ))}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <chart.component data={data} categories={categories} {...chart.props} />
      </div>
      {isHalf && !isFirstOfCouple && <DropArea position="below" chart={chart} />}
    </>
  );
}

ChartContainer.propTypes = {
  ...CHART_PROP_TYPES,
  chart: propTypes.shape({
    label: propTypes.string.isRequired,
    component: propTypes.func.isRequired,
    isHalf: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  }).isRequired,
};
