import React from 'react';
import { Base } from './Base';

export function IconHeaderMap() {
  return (
    <Base isWide>
      <path fillRule="evenodd" d="M12 4h1V3l1 1V3h2l-1 1h-1c1 0 0 0 0 0h-1-1zm-1 11l-1 1H9v-1-1l-1 1v-1c0-1 0 0 0 0H7v-1-1h2v1-1l1-1 1-1h1V9l1 1V8h1v1-1-1h-1V6l-2 2s0 1 0 0l-1-1 1-1h1l1-1h-2L9 4 8 5H3L2 7h3l-1 3v1l1 1v0l1 1v1l1 1v-1 1h1v1h2-1l1 1H9v2l1 1h1v4l1 2c1 0 0 0 0 0v1h1c1 0 0 0 0 0l-1-1v-1h1v-1l1-1v-1h1v-1-1l1-1v-1h-1-1v-1h-1v-1h-1-1v-1zm-1-2H9l1 1h1l-1-1zm5-9h1v3l1-1h1V5h1l1-1s1 0 0 0V3h-1-1l-2 1a5 5 0 01-1 0zm1 0zm-3 2h1l-1-1h-1 2c1 0 0 0 0 0v1c-1 0 0 0 0 0h-1zm27-1l3 1-2 1h1v1l-1-1V6h-1v1h-1v1h1v2h-1l1 1h-1v-1h-1v1l1 1-1 1-1 1 1 1-1 1-1-1v1l1 1h-1v-3h-1v-1l-1 1-1 1v1l-1-2v-1 1l-1-1h-1l-1-1v1-1h-1l1 1h1l-1 1-1 1-2-3 2 4 1-1v2h-1l-1 1v4l-2 2h-1l-1-4v-3l-1-1-1 1h-1l-1-1v-1-1-1l2-2h2l1 1v-1l2 1v-1h-1v-1h2V9h-1l-1 1v1l-1-1-1-1 1 1-1 1v-1-1l-1 1-1 1h-1V9l1 1V9 8h1V7h1v1h1V7h1l-1-1v1h-1-1V6l1-1h1l2 1h-1 2c1 0 0 0 0 0l5-2h1l1 1h6zm-7 11c1 0 1 0 0 0zm3 1v-1l1 2h-1v-1zm2 1h1a8 8 0 000-1v-1l-1 1v1zm-1 1h3-1-2zm8 6l-1 1 1-1h1v-1l-1 1zm-7-1v-1-1-1h1l1-1h2s-1 0 0 0v0l1-1v2l1 1-1 1v1h-1v1h-1v-1-1h-1-1l-1 1zM20 8l1-1v1h-1zm-1-2h1-1zm9 15v-1h1l-1 2v-1zM21 8V7v1h1-1zm19 8v-1l-1-1v1l1 1zm1-5l-1 1v-1l1-1-1-1a21 21 0 000-1v1h1v2zm-2 7h1v-1h-1v1zm2 0l-1-1h1v1h2v1h1-2v-1h-1z" />
    </Base>
  );
}
