import React from 'react';
import { Base } from './Base';

export function IconShare() {
  return (
    <Base>
      <path fillRule="evenodd" d="M23.7 5.8H8.3c-1.2 0-2.1 1-2.1 2.1v15.4a2 2 0 002.1 2.1h15.4c1.1 0 2.1-1 2.1-2.1V7.9c0-1.1-1-2.1-2.1-2.1zm-.3 17.5H8.6c-.2 0-.3 0-.3-.3V8.2c0-.2 0-.3.3-.3h14.8c.2 0 .3.1.3.3V23c0 .2-.1.3-.3.3zM21.1 10h-6c-.3 0-.5.3-.5.6v1.2c0 .3.2.5.5.5l2.6-.1-7.2 7.1c-.2.2-.2.5 0 .8l1 1c.3.2.6.2.8 0l7.1-7.2v2.6c-.1.3.1.5.5.5H21c.3 0 .6-.2.6-.5v-6c0-.2-.3-.5-.5-.5z" />
    </Base>
  );
}
