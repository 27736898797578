import { useMemo } from "react";
import _ from 'lodash';
import { useSaving } from "../core";

export function useScrollableChart() {
  const uid = useMemo(() => _.uniqueId('datasailsChart'), []);
  const { saving } = useSaving();

  /**
   * Unminified source:
   */
  // (function () {
  //   var chart = document.getElementById('datasailsChart1');
  
  //   if (chart) {
  //     var cornerCover = chart.querySelector('.corner-cover');
  //     chart.addEventListener('scroll', function () {
  //       var scrollTop = chart.scrollTop,
  //           scrollLeft = chart.scrollLeft;
  //       cornerCover.style.bottom = "".concat(scrollTop * -1, "px");
  //       cornerCover.style.left = "".concat(scrollLeft, "px");
  //     });
  //   }
  // })();
  const scriptSrc = `data:application/javascript;base64,${btoa(`!function(){var t=document.getElementById("${uid}");if(t){var e=t.querySelector(".corner-cover");t.addEventListener("scroll",function(){var o=t.scrollTop,c=t.scrollLeft;e.style.bottom="".concat(-1*o,"px"),e.style.left="".concat(c,"px")})}}();`)}`

  return { uid, saving, scriptSrc };
}