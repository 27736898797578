export function scatterLabelFormatter(val, index) {
  const { seriesIndex, dataPointIndex, w, series } = index;

  if (series) {
    return val;
  }

  const item = w.config.series[seriesIndex].data[dataPointIndex];
  return item?.name || val;
}

export const scatterLabelFormatterConstruct = [
  'val',
  'index',
  `var seriesIndex = index.seriesIndex,dataPointIndex = index.dataPointIndex,w = index.w,series = index.series;if (series) return val;var item = w.config.series[seriesIndex].data[dataPointIndex];return (item === null || item === void 0 ? void 0 : item.name) || val;`,
];
