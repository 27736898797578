import React from 'react';
import propTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import _ from 'lodash';
import arrayMove from 'array-move';
import { useCategories, useData } from '../../core';

export function DropArea({ notAccepted, position, rows }) {
  const { categories, setCategories } = useCategories();
  const { data } = useData();

  const [{ isOver, isActive }, drop] = useDrop({
    accept: data[0].categories.filter(c => c.name !== notAccepted).map(c => c.name),
    drop: item => {
      const currentCategory = _.find(categories, { name: notAccepted });
      const itemIndex = _.findIndex(categories, { name: item.type });
      const currentIndex = categories.indexOf(currentCategory);
      let newItemIndex = position === 'left' ? currentIndex : currentIndex + 1;

      if (itemIndex < currentIndex) {
        newItemIndex = position === 'left' ? currentIndex - 1 : currentIndex;
      }

      const newCategories = arrayMove(
        categories,
        itemIndex,
        newItemIndex
      );

      setCategories(newCategories);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isActive: monitor.getItem() && monitor.getItem().type !== notAccepted,
    }),
  });

  return (
    <td rowSpan={rows + 1} className={classNames(`drop-area is-${position}`, { 'is-over': !!isOver, 'is-active': isActive })} ref={drop} />
  );
}

DropArea.propTypes = {
  notAccepted: propTypes.string.isRequired,
  position: propTypes.oneOf(['left', 'right']).isRequired,
  rows: propTypes.number.isRequired,
};
