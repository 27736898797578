export const REGIONS = {
  AFR: {
    name: "Africa",
    countries: ["AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CD", "CG", "GQ", "ER", "ET", "GA", "GH", "GN", "KE", "LS", "LR", "MG", "MW", "ML", "MZ", "NA", "NE", "NG", "RW", "ST", "SN", "SC", "SO", "ZA", "SS", "SD", "SZ", "TZ", "TG", "UG", "ZM", "ZW", "GM", "CI", "GW", "MR", "SL", "MU"]
  },
  PAC: {
    name: "East Asia and Pacific",
    countries: ["KH", "CN", "FJ", "ID", "KI", "MY", "MH", "MN", "MM", "PW", "PG", "PH", "WS", "SB", "TH", "TO", "TV", "VU", "VN", "KR", "LA", "AU", "JP", "SG", "TW", "NZ", "KP", "BN", "FM", "NR", "TL", "NC", "CK", "NU"]
  },
  EUR: {
    name: "Europe and Central Asia",
    countries: ["AL", "AM", "AZ", "BY", "BA", "BG", "HR", "CZ", "EE", "GE", "HU", "KZ", "XK", "LV", "LT", "MD", "ME", "PL", "RO", "RS", "SI", "TJ", "TR", "TM", "UA", "UZ", "MK", "KG", "RU", "SK", "AT", "BE", "GB", "VA", "LI", "LU", "DK", "FI", "FR", "DE", "GR", "IS", "IE", "IT", "ES", "SE", "CH", "NL", "NO", "PT", "AD", "CY", "MT", "MC", "SM"],
  },
  SAM: {
    name: "Latin America and Caribbean",
    countries: ["AG", "AR", "BB", "BZ", "BO", "BR", "CL", "CO", "CR", "EC", "SV", "GD", "GT", "GY", "HT", "HN", "JM", "MX", "NI", "PA", "PY", "PE", "KN", "LC", "VC", "TT", "UY", "BS", "DO", "VE", "CU", "SR", "DM", "GP", "MQ", "SX", "BL"]
  },
  MES: {
    name: "Middle East and North Africa",
    countries: ["DZ", "BH", "DJ", "IQ", "JO", "LB", "LY", "MA", "TN", "PS", "EG", "YE", "SY", "IR", "AE", "OM", "QA", "IL", "KW", "SA"]
  },
  NAM: {
    name: "North America",
    countries: ["CA", "US"]
  },
  SAS: {
    name: "South Asia",
    countries: ["AF", "BD", "BT", "IN", "MV", "NP", "LK", "PK"]
  },
  WRL: {
    name: "World",
    isWorld: true
  }
};
REGIONS.WRL.countries = Object.values(REGIONS).reduce((countries, region) => {
  if (region.countries) {
    return countries.concat(region.countries);
  }
  return countries;
}, []);

/** @type {{ [key: string]: string }} */
export const REGIONS_BY_NAME = Object.values(REGIONS).reduce((map, region) => {
  // eslint-disable-next-line no-param-reassign
  map[region.name] = region.countries;
  return map;
}, {});
