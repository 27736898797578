import _ from 'lodash';
import { isEmpty } from './isEmpty';
import { currencyFormat } from './currencyFormat';
import { dateFormat } from './dateFormat';
import { INVESTMENT_UNDISCLOSED } from '../const';

export function tableChartDataBuilder(categories, data) {
  const categoriesNames = categories.map(category => category.name);

  const rows = data.map(row => {
    const result = row.categories.filter(category => categoriesNames.includes(category.name))
      .sort((a, b) => {
        const aIndex = categoriesNames.indexOf(a.name);
        const bIndex = categoriesNames.indexOf(b.name);
        if (aIndex > bIndex) {
          return 1;
        }
        if (bIndex > aIndex) {
          return -1;
        }
        return 0;
      })
      .map((category) => {
        const isNull = !category || isEmpty(category.value);

        let value = isNull && category.type === 'currency' ? INVESTMENT_UNDISCLOSED : '';

        if (!isNull) {
          switch (category.type) {
            case 'currency':
              value = currencyFormat(category.value, category.currency);
              break;
            case 'date':
              value = dateFormat(category.value);
              break;
            case 'number':
              value = category.value;
              break;
            case 'country':
            default:
              value = (Array.isArray(category.value) ? category.value.join(', ') : category.value).trim();
          }
        }
        return [category.name, value, typeof category.value === 'string' ? category.value.trim() : category.value];
      });
    result.push(_.uniqueId('datasailsTableChart'));
    return result;
  });

  const total = currencyFormat(rows.reduce((sum, row) => sum + _.find(row, { 0: 'Investment' })?.[2] || 0, 0));

  return {
    headers: categoriesNames,
    rows,
    total,
  };
}
