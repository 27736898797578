import _ from 'lodash';
import { DEFAULT_CATEGORY } from '../const';

export function dotPlotDataBuilder(categories, data) {
  const aggregatorCategories = _.filter(categories, 'isArray').slice(0, 2);
  const categoryAggregator = aggregatorCategories[0];
  const categoryAggregator2 = aggregatorCategories[1];
  const categoryValue = _.find(categories, { type: 'currency' });
  const categoryName = DEFAULT_CATEGORY;

  let { aggregators, aggregators2 } = data.reduce((result, row) => {
    const aggregator = row.categories.find(category => category.name === categoryAggregator.name);
    const aggregator2 = row.categories.find(category => category.name === categoryAggregator2.name);
    aggregator.value.forEach(item => result.aggregators.add(item));
    aggregator2.value.forEach(item => result.aggregators2.add(item));
    return result;
  }, { aggregators: new Set(), aggregators2: new Set() });
  aggregators = Array.from(aggregators);
  aggregators2 = Array.from(aggregators2);

  const result = [];
  let max = 0;

  aggregators.forEach(aggregator => {
    const group = {
      label: aggregator,
      cells: aggregators2.map(aggregator2 => {
        const rowData = data.filter(row => (
          row.categories.find(category => category.name === categoryAggregator.name && category.value.includes(aggregator)) &&
          row.categories.find(category => category.name === categoryAggregator2.name && category.value.includes(aggregator2)) &&
          _.find(row.categories, { name: categoryValue.name })
        ));
        const value = rowData.reduce((total, row) => {
          const rowValue = _.find(row.categories, { name: categoryValue.name });
          return total + rowValue.value;
        }, 0);

        if (value > max) {
          max = value;
        }

        let isSplit = false;
        const reducedData = rowData.reduce((projects, project) => {
          const projectValue = _.find(project.categories, { name: categoryValue.name })?.value || 0;
          if (projectValue) {
            const projectDate = _.find(project.categories, { type: 'date' }).value;
            const projectAggregatorData = _.find(project.categories, { name: categoryAggregator.name }).value;
            const projectAggregator2Data = _.find(project.categories, { name: categoryAggregator2.name }).value;
            const isProjectSplit = [projectAggregatorData, projectAggregator2Data].some(aggregatorData => aggregatorData.length > 1);
            isSplit = isSplit || isProjectSplit;
            let projectLabel = _.find(project.categories, { name: categoryName.name }).value;
            if (Array.isArray(projectLabel)) {
              projectLabel = projectLabel.join(', ');
            }

            const existingEntry = _.find(projects, { label: projectLabel });
            if (existingEntry) {
              existingEntry.value += projectValue;
              existingEntry.isSplit = existingEntry || isProjectSplit;
            } else {
              projects.push({
                label: projectLabel,
                value: projectValue,
                date: projectDate,
                isSplit: isProjectSplit,
              });
            }
          }
          return projects;
        }, []);

        return {
          aggregator2,
          value,
          data: reducedData,
          isSplit,
        };
      }),
    };
    result.push(group);
  });

  return {
    data: result,
    max,
    aggregators,
    aggregators2,
  };
}
