import { useDispatch, useSelector } from 'react-redux';
import {
  getCategories,
  addCategory as reduxAddCategory,
  removeCategory as reduxRemoveCategory,
  setCategories as reduxSetCategories,
} from '../redux';

export function useCategories() {
  const dispatch = useDispatch();
  const categories = useSelector(getCategories);

  function addCategory(category) {
    return dispatch(reduxAddCategory(category));
  }

  function removeCategory(category) {
    return dispatch(reduxRemoveCategory(category));
  }

  function setCategories(newCategories) {
    return dispatch(reduxSetCategories(newCategories));
  }

  return {
    categories,
    addCategory,
    removeCategory,
    setCategories,
  };
}