import _ from "lodash";
import {
  TableChart,
  ScatterChart,
  StackedChart,
} from "../charts";
import { DotPlotChart, GeoChart, BarChart } from "../charts/components";
import { isChartTypeAllowed } from "../util";
import {
  IconHeaderMap,
  IconTableChart,
  IconBarChart,
  IconMapChart,
  IconScatterChart,
  IconDotPlotChart,
  IconStackedChart,
} from "../icons";

export const MAP_CHART_TYPE = {
  label: "Map",
  icon: IconHeaderMap,
  component: GeoChart,
  requiredTypes: []
};

const getUniqueCategoryValues = (categories, data, categoryType) => {
  const category = categories.find(c => categoryType.includes(c.type));
  const categoryValues = new Set();

  data.forEach(d => {
    const dc = d.categories.find(c => c.name === category.name);
    if (Array.isArray(dc.value)) {
      dc.value.forEach(dcv => categoryValues.add(dcv));
    } else {
      categoryValues.add(dc.value);
    }
  });

  return Array.from(categoryValues);
};

export const CHART_TYPES = [
  {
    label: "Table",
    icon: IconTableChart,
    component: TableChart,
    requiredTypes: [
      ["currency", "number", "date", "country", "string"]
    ],
    enabled(categories) {
      return isChartTypeAllowed(this, categories);
    }
  },
  {
    label: "Bars",
    icon: IconBarChart,
    component: BarChart,
    requiredTypes: [
      ["string", "country"],
      ["currency", "number"]
    ],
    enabled(categories) {
      const aggregators = categories.filter(c => !['currency', 'number', 'date'].includes(c.type));
      return aggregators.length <= 3 && isChartTypeAllowed(this, categories);
    }
  },
  {
    label: "on Map",
    icon: IconMapChart,
    component: GeoChart,
    requiredTypes: [
      ["currency", "number"],
    ],
    enabled(categories) {
      return isChartTypeAllowed(this, categories);
    },
    props: {
      hasInvestment: true,
    },
  },
  {
    label: "Scatter",
    icon: IconScatterChart,
    component: ScatterChart,
    requiredTypes: [["string", "country"], "date"],
    enabled(categories) {
      return isChartTypeAllowed(this, categories);
    }
  },
  {
    label: "Dot Plot",
    icon: IconDotPlotChart,
    component: DotPlotChart,
    requiredTypes: [
      ["string", "country"],
      ["string", "country"],
      ["currency", "number"]
    ],
    enabled(categories) {
      return (
        isChartTypeAllowed(this, categories) &&
        _.filter(categories, "isArray").length >= 2
      );
    }
  },
  {
    label: "Stacked",
    icon: IconStackedChart,
    component: StackedChart,
    requiredTypes: [["string", "country"], ["currency", "number"], "date"],
    enabled(categories, data) {
      return (
        isChartTypeAllowed(this, categories) &&
        getUniqueCategoryValues(categories, data, ["string", "country"])
          .length <= 16
      );
    }
  }
];
