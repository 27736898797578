import React from 'react';
import propTypes from 'prop-types';
import { currencyFormatUnits } from '../../util';

export function BarAxisIndicators({ maxValue }) {
  const values = Array(5).fill(0).map((_, index) => maxValue * 0.16666 * (index + 1));

  return (
    <div className="bar-axis">
      {values.map((value) => (
        <div className="axis-value" key={value}>
          {currencyFormatUnits(value, 'USD', 'en-US', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }, false)}
        </div>
      ))}
    </div>
  );
}

BarAxisIndicators.propTypes = {
  maxValue: propTypes.number.isRequired,
};
