import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { store } from './core';
import App from './App';
import './styles/main.scss';

const rootEl = document.getElementById('root');

ReactDOM.render((
  <Provider store={store}>
    <DndProvider backend={TouchBackend} options={{enableMouseEvents: true, enableTouchEvents: true}}>
      <App />
    </DndProvider>
  </Provider>
), rootEl);
